@use "../../utilities";
@use "../../variables";

.site-footer {
    background: variables.$color-gray;
    padding: 0 0 60px;
    margin:0;
    z-index: 1;
}
.footer-subscribe {
    text-align: center;
}
.footer-subscribe-wrap {    
    border-bottom: 1px solid variables.$color-dark-gray;
    padding-top: 80px;
    padding-bottom: 80px;
}
.footer-subscribe__title {
    margin-bottom: variables.$grid-gutter;
	@include utilities.fluid-type(variables.$bp-s, variables.$container-size-em, 44px, 60px);
}
.footer-subscribe__desc {
    margin: auto auto variables.$grid-gutter;
    max-width: 800px; 
	@include utilities.fluid-type(variables.$bp-s, variables.$container-size-em, 20px, 24px);
}
.footer-subscribe__form {
    position: relative;
    margin: auto;
    max-width: 720px; 
}
.footer-subscribe__form-group {
    position: relative;
}
.footer-subscribe__label {
    font-size: utilities.pxtorem(20px);
    position: absolute;
    pointer-events: none;
    left: variables.$grid-gutter;
    top: 15px;
    transition: variables.$trans;
}
.filled .footer-subscribe__label {
    font-size: utilities.pxtorem(14px);
    top: 5px;
    color: variables.$color-primary;
    font-weight: variables.$fontWeight-extrabold;
}
.footer-subscribe__input {
    height: 60px;
    width: 100%;
    padding-left: variables.$grid-gutter;
    padding-top: 20px;
    border-radius: 30px;
    border-color: transparent;
    box-shadow: 0 0 20px 10px rgba(0,0,0,.05);
    border: 1px solid transparent;

    &:hover, 
    &:active, 
    &:focus {
        border: 1px solid variables.$color-primary;
    }
}
.footer-subscribe__btn {
    border: 1px solid transparent;
    width: 100%;
    @include utilities.breakpoint(variables.$bp-mw) {
        position: absolute;
        right: -1px;
        top: 0;
        z-index: 1;        
        width: auto;
    }
}
.footer-subscribe__message {
    font-size: utilities.pxtorem(24px);
    margin-bottom: 10px;
    font-weight: bold;
}
.footer-top {
    padding: 80px variables.$grid-gutter;
}
.footer-top div[class^="col-"] {    
    border-bottom: 1px solid variables.$color-dark-gray;
    padding-bottom: 80px;
    margin-bottom: 80px;
    padding-left: 0;
    padding-right: 0;
    &:last-child {
        margin-bottom: 0;
    }

    @include utilities.breakpoint(variables.$bp-m) {
        border-bottom: 0;
        padding-bottom: 0;
        padding-left: variables.$grid-gutter;
        padding-right: variables.$grid-gutter;
        margin-bottom: 0;
        &:last-child {
            border-left: 1px solid variables.$color-dark-gray;
        }
    }
}
.footer-links {
    @include utilities.resetList;
}
.footer-links__item {
    margin-bottom: 20px;
}
.footer-links__link {
    font-weight: variables.$fontWeight-bold;
    font-size: utilities.pxtorem(17px);
    text-decoration: none;
    &:hover, 
    &:active, 
    &:focus {
        text-decoration: underline;
    }
}
.footer-item {
    margin-bottom: 20px;
}
.footer-copyright {
    text-align: left;
    @include utilities.breakpoint(variables.$bp-mw) {
        text-align: center;
        display: flex;
        justify-content: center;
    }
}
.footer-copyright-text {
    margin-bottom: 10px;
    @include utilities.breakpoint(variables.$bp-mw) {
        margin-bottom: 0;
    }
}

.footer-copyright-links {
    @include utilities.breakpoint(variables.$bp-mw) {
        display: flex;
    }
}
.footer-copyright-links a {
    display: block;
    margin-bottom: 10px;
    @include utilities.breakpoint(variables.$bp-mw) {
        margin-bottom: 0;
        margin-left: 10px;
        &::before {
            content: '|';
            position: relative;
            padding-right: 10px
        }
    }
}
.footer__badges {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 250px;
}
.footer__badge-link {
    text-decoration: none;
    display: block;
    margin-bottom: 20px;
    transition: variables.$trans;
    transform: scale(1);
    &:hover, 
    &:active, 
    &:focus {
        transform: scale(1.02);
    }

    img {
        height: 50px;
    }
}

.social-list { 
    @include utilities.resetList;
    display:flex;
    margin:0 -5px;
}
.social-list__item {
    padding:0 5px;
}
.social-list__link {
    display: block;
    width: 48px;
    height: 48px;
    margin: 0 auto;
    position: relative;
    color: #42494A;
    transition: variables.$trans;
    transform: scale(1);

    &:hover,
    &:focus {
        color:variables.$color-primary;
        transform: scale(1.1);
    }
}
.social-list__icon { 
    fill: currentColor;
    width: 22px;
    height: 22px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}