@use "../../utilities";
@use "../../variables";

/* ================================================================
Base styles
================================================================ */

.site-container {
}

.section-container {
    padding:30px 0;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    &--padding-top {
        padding:30px 0 0;
    }
    &--padding-bottom {
        padding:0 0 30px;
    }

    &--white {
        background-color: #ffffff;
    }
    &--gray {
        background-color:variables.$color-gray;
    }

    &--padding-small {
        padding: 15px 0;

        &.section-container--padding-top {
            padding:15px 0 0;
        }
        &.section-container--padding-bottom {
            padding:0 0 15px;
        }
    }

    &--padding-medium {
        padding: 30px 0;

        &.section-container--padding-top {
            padding:30px 0 0;
        }
        &.section-container--padding-bottom {
            padding:0 0 30px;
        }
    }

    &--padding-large {
        padding: 50px 0;

        &.section-container--padding-top {
            padding:50px 0 0;
        }
        &.section-container--padding-bottom {
            padding:0 0 50px;
        }
    }

    &--no-padding {
        padding: 0;
    }

    &--curved {
        margin: 8vw 0;
        position: relative;
        background-color:variables.$color-gray;
        
        &::before,
        &::after { 
            content: ''; 
            display: block; 
            width: 100%;
            position: absolute;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            background-color: variables.$color-gray;
            -webkit-mask-position:bottom;
            mask-position:bottom;
            -webkit-mask-size: 100% 8vw;
            mask-size: 100% 8vw;  
            height: 8vw;        
        }
        &::before {
            -webkit-mask-image: url(../images/curved-section-top.png);
            mask-image: url(../images/curved-section-top.png);
            top: calc(-8vw + 1px); // fix for 1px white line on different screen resolutions
        }
        &::after {
            -webkit-mask-image: url(../images/curved-section-bottom.png);
            mask-image: url(../images/curved-section-bottom.png);
            bottom: calc(-8vw + 1px); // fix for 1px white line on different screen resolutions
        }
    }

    &--curved-top {
        margin-bottom: 0;
        &::after {            
            display: none;
        }
    }
    &--curved-bottom {
        margin-top: 0;
        &::before {            
            display: none;
        }
    }

    &--curved-two-tone {
        background-image: url(../images/curved-gray-two-tone.png);
        background-size: cover;

        @include utilities.breakpoint(variables.$bp-mw) {            
            background-size: 100% 100%;
        }
    }
    
    &--img {
        background-size: 100%;
        background-position: bottom -210px center;
        padding-bottom: 30%;
    }
}

@media (min-width: variables.$bp-mw) {
    .section-container {
        padding:50px 0;

        &--padding-top {
            padding:50px 0 0;
        }
        &--padding-bottom {
            padding:0 0 50px;
        }
    
        &--padding-small {
            padding: 20px 0;

            &.section-container--padding-top {
                padding:20px 0 0;
            }
            &.section-container--padding-bottom {
                padding:0 0 20px;
            }
        }
    
        &--padding-medium {
            padding: 50px 0;

            &.section-container--padding-top {
                padding:50px 0 0;
            }
            &.section-container--padding-bottom {
                padding:0 0 50px;
            }
        }
    
        &--padding-large {
            padding: 120px 0;

            &.section-container--padding-top {
                padding:120px 0 0;
            }
            &.section-container--padding-bottom {
                padding:0 0 120px;
            }
        }
    
        &--no-padding {
            padding: 0;
        }

        &--img {
            background-position: bottom -410px center;
            padding-bottom: 20%;
        }
    }
}

.section-disclaimer .disclosures-header {
    border-top: 1px solid variables.$color-dark-gray;
    padding-top: 50px;
}
.section-dashed {   
    position: relative; 
    padding-top:120px;
    padding-bottom:120px;

    &::before,
    &::after { 
        content: ''; 
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 10px;
        background-image: radial-gradient(circle at 5px 5px, variables.$color-secondary 3px, transparent 0);
        background-size: 30px 10px;
        background-repeat: repeat-x;
    }
    &::before {
        top: 0;
    }
    &::after {
        bottom: 0;
    }
    &--top {
        padding-bottom: 0;
        &::after { 
            display: none;
        }
    }
    &--bottom {
        padding-top: 0;
        &::before { 
            display: none;
        }
    }
}

/******************************** 
** Modals Styling 
********************************/
.modaal-inline .modaal-container {
    border-radius: 20px;

    h2 {font-size: utilities.pxtorem(28px);}
    h3 {font-size: utilities.pxtorem(24px);}
}

.modal-cta__btns {
    margin: 40px auto 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    .btn {
        margin: 0;
    }
}
.modaal-wrapper .modaal-close {
    &:hover, 
    &:active, 
    &:focus {
        outline: revert;
    }
}