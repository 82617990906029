@use "sass:math";
@use "../../utilities";
@use "../../variables";

/* ================================================================
   Tasks
================================================================ */

.tasks {

}
.tasks .title {
    text-align: center;
}
.tasks-carousel {    
    @include utilities.breakpoint(variables.$bp-mw) {
        display: flex;        
    }
}
.tasks-carousel__item {
    text-align: center;
    padding: variables.$grid-gutter;
    border-radius: 30px;
    margin: 30px auto;
    &.slick-current {
        background-color: #fff;
        box-shadow: 0 0 10px 10px rgba(0,0,0,.05);
    }
    &:first-child.slick-current {
        background-color: transparent;
        box-shadow: none;
    }
    @include utilities.breakpoint(variables.$bp-mw) {
        flex: 1;
    }
}
.tasks-carousel__figure {
    margin-bottom: variables.$grid-gutter;
}
.tasks-carousel__img {
    display:block;
    background: no-repeat center center / cover;
    max-width: 280px;
    border-radius: 50%;
    margin: auto;
    box-shadow: 0 5px 10px 5px rgba(0,0,0,.05);
    &::after { 
        content: ''; 
        display: block; 
        padding-top: math.percentage(math.div(1, 1)); 
    }
}
.tasks-carousel__desc {
    margin-bottom: variables.$grid-gutter;

    ul,
    ol {
        text-align: left;
    }
}
.tasks-carousel__nav {
    display: none;
    @include utilities.breakpoint(variables.$bp-mw) {
        align-items: center;
        justify-content: center;
        margin: 40px 0;
    }
}
.tasks-carousel__counter {
    font-weight: variables.$fontWeight-bold;
    margin: 0 60px;
}
.slick-arrow {
    font-size: 0;
    border: none;
    background-color: transparent;
    padding: 10px;
    cursor: pointer;
    transition: variables.$trans;

    // Arrow
    &:after {
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        display: inline-block;
        height: 12px;
        width: 12px;
        position: relative;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: rotate(45deg);
        vertical-align: top;
        border-color: variables.$color-base;
    }
    &.tasks-carousel__arrow-prev {
        &:after {
            transform: rotate(-135deg);
        }  
    }

    &:hover, 
    &:active, 
    &:focus {
        &:after {
            border-color: variables.$color-purple;
        }
    }
}