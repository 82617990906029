@use "../../utilities";
@use "../../variables";

/* ================================================================
   Parallax Dots
================================================================ */

@mixin dot($top: 50%, $left: 50%, $right: inherit, $color: variables.$color-primary, $diam: 30px){
    top: $top;
    left: $left;
    right: $right;
    background: $color;
    width: $diam;
    height: $diam;
}

.parallax {
    position: relative;
}

span.parallax-dot {
    display: none;
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    pointer-events: none;
    @include utilities.breakpoint(variables.$bp-mw) {
        display: block;
        /*&:nth-of-type(1){
            @include dot(40%, 0,inherit , $color-orange, 20px);
        }*/
        &:nth-of-type(2){
            @include dot(35%, 45%, inherit , variables.$color-secondary, 30px);
        }
        &:nth-of-type(3){
            @include dot(10%, 92%, inherit , variables.$color-primary, 35px);
        }
        /*&:nth-of-type(4){
            @include dot(75%, 5%, inherit, $color-primary, 10px);
        }*/
        &:nth-of-type(5){
            @include dot(90%, 52%, inherit, variables.$color-purple, 90px);
        }
        &:nth-of-type(6){
            @include dot(90%, 43%, inherit, variables.$color-orange, 20px);
        }
    }
}

.parallax {
    &--hero {  
        span.parallax-dot { 
            display: block;

            /*&:nth-of-type(1){
                @include dot(40%, 5%,inherit , $color-orange, 20px);
            }*/
            &:nth-of-type(2){
                @include dot(35%, 10%, inherit , variables.$color-secondary, 30px);
            }
            &:nth-of-type(3){
                @include dot(32%, 89%, inherit , variables.$color-primary, 35px);
            }
            /*&:nth-of-type(4){
                @include dot(75%, 1%, inherit, $color-primary, 10px);
            }*/
            &:nth-of-type(5){
                display: none;
                @include dot(50%, 85%, inherit, variables.$color-purple, 90px);
            }
            &:nth-of-type(6){
                @include dot(90%, 87%, inherit, variables.$color-orange, 20px);
            }
            @include utilities.breakpoint(variables.$bp-mw) {
                /*&:nth-of-type(1){
                    @include dot(40%, 5%,inherit , $color-orange, 20px);
                }*/
                &:nth-of-type(2){
                    @include dot(35%, 50%, inherit , variables.$color-secondary, 30px);
                }
                &:nth-of-type(3){
                    @include dot(32%, inherit, 40px , variables.$color-primary, 35px);
                }
                /*&:nth-of-type(4){
                    @include dot(75%, 40px, inherit, $color-primary, 10px);
                }*/
                &:nth-of-type(5){
                    display: block;
                    @include dot(inherit, 50%, inherit, variables.$color-purple, 60px);
                    bottom: 60px;
                }
                &:nth-of-type(6){
                    @include dot(inherit, 43%, inherit, variables.$color-orange, 20px);
                    bottom: 20px;
                }
                
            }
        }
    }

    &--footer {  
        span.parallax-dot {          
            &:nth-of-type(1){
                @include dot(14%, 7%, inherit, variables.$color-primary, 25px);
            }
            &:nth-of-type(2){
                @include dot(-30px, inherit, 0, variables.$color-secondary, 60px);
            }
            &:nth-of-type(3){
                @include dot(7%, 4%, inherit, variables.$color-orange, 35px);
            }
            &:nth-of-type(4){
                @include dot(85%, -40px, inherit, variables.$color-secondary, 60px);
            }
            &:nth-of-type(5){
                @include dot(70%, 90%, inherit, variables.$color-orange, 20px);
            }
            &:nth-of-type(6){
                @include dot(68%, 88%, inherit, variables.$color-primary, 10px);
            }
            &:nth-of-type(7){
                @include dot(10%, 88%, inherit, variables.$color-purple, 10px);
            }
        }
    }
}