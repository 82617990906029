@use "../../utilities";
@use "../../variables";
@use './get-started';

/* ================================================================
   Home Loan Form
================================================================ */

.home-loan {
    background-color: variables.$color-secondary;
    padding: 30px 50px;
    max-width: variables.$container-size-small;
    margin: auto;
    border-radius: 40px;
    text-align: center;
    margin-bottom: 60px;

    @include utilities.breakpoint(variables.$bp-mw) {        
        padding: 20px 50px;
        border-radius: 900px;
    }

    h2 {
        margin-bottom: 20px;
        line-height: 1;
        font-size: utilities.pxtorem(44px);
    }
}
.home-loan__form {
    @include utilities.breakpoint(variables.$bp-mw) {
        display: flex;
        align-items: center;
        justify-content: center;        
    }
}
.home-loan__col {
    margin-bottom: 15px;

    @include utilities.breakpoint(variables.$bp-mw) {
        margin-right: 20px;
        margin-bottom: 0;
    }

    &--dropdown {
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;

        @include utilities.breakpoint(variables.$bp-mw) {
            width: 50%;
            margin: 0 20px 0 0;
        }
    }

    .btn {
        margin: 10px;
    }
}
.home-loan__label {
    font-size: utilities.pxtorem(26px);
    font-weight: variables.$fontWeight-extrabold;
    @include utilities.breakpoint(variables.$bp-mw) {
        font-size: utilities.pxtorem(36px);
    }
}
.home-loan__dropdown {
    @extend .get-started__dropdown;
    font-family: variables.$font-primary;
    font-size: utilities.pxtorem(19px);
    font-weight: variables.$fontWeight-bold;
    padding: 10px 20px;
}

.home-loan__form--step2 {
    flex-direction: column;

    .home-loan__col {
        margin-bottom: 15px;
    }
}
.home-loan__sub {
    display: flex;
    font-size: utilities.pxtorem(26px);
    font-weight: variables.$fontWeight-extrabold;
    margin: auto;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    line-height: 1.2;

    @include utilities.breakpoint(variables.$bp-mw) {
        font-size: utilities.pxtorem(26px);        
    }
    
    &::before,
    &::after { 
        @include utilities.breakpoint(variables.$bp-mw) {
            content: ''; 
            display: block; 
            background-color: variables.$color-base;
            width: 70px;
            height: 1px;
            margin: 10px 20px 0;
        }
    }
}

.home-loan__description {
    font-size: utilities.pxtorem(22px);
    text-align: center;
    margin:0 0 20px;

    &--result {
        background: #fff;
        padding: 40px;
        max-width: 600px;
        margin: 30px auto 20px;
        border-radius: 10px;
        border: 2px dashed #000;
    }
}