@use "../../utilities";
@use "../../variables";

/* ================================================================
   Accordions
================================================================ */

.accordion {
}
.accordion__controls {
    @include utilities.resetList;
}
.accordion__item {
    background-color: #fff;
    border: 1px solid variables.$color-dark-gray;
    border-radius: 5px;
    margin-bottom: 30px;
    position: relative;

    &::before { 
        content: ''; 
        display: block;
        position: absolute;
        background-color: #fff;
        border-radius: 30px;
        box-shadow: none;
        height: 60px;
        width: 100%;
        left: 0;
        transition: variables.$trans;
    }

    &.active {
        @include utilities.breakpoint(variables.$bp-mw) {
            border-radius: 30px;
            border-color: transparent;
            &::before { 
                background-color: #fff;
                border-radius: 30px;
                box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.05);
                height: 100%;
                width: calc(100% + 30px);
                left: -15px;
            }            
        }
        @include utilities.breakpoint(1780px) {
            &::before {
                width: calc(100% + 120px);
                left: -60px;
            }
        }
    }
}
.accordion__control--header {
    margin: 0;
}
.accordion__control {
    border: none;
    background-color: transparent;
    font-size: utilities.pxtorem(24px);
    line-height: 1;
    font-weight: variables.$fontWeight-bold;
    font-family: 'Mulish', sans-serif;
    margin: 0;
    padding: 20px;
    width: 100%;
    text-align: left;
    cursor: pointer;

    @include utilities.breakpoint(variables.$bp-mw) {
        padding: 40px 50px;
    }

    &[data-expander-toggle] {
        position: relative;
        padding-right: 40px;
        @include utilities.breakpoint(variables.$bp-mw) {
            padding-right: 60px;            
        }

        // Arrows
        &:after {
            border-style: solid;
            border-width: 2px 2px 0 0;
            content: "";
            display: inline-block;
            height: 8px;
            width: 8px;
            right: 20px;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            transform: rotate(135deg);
            vertical-align: top;
            border-color: variables.$color-base !important;
            @include utilities.breakpoint(variables.$bp-mw) {
                right: 40px;      
            }
        }

        &.active {
            &:after {
                transform: rotate(-45deg);
            }
        }
    }

    h3, .accordion__title  {
        font-size: utilities.pxtorem(24px);
        line-height: 1;
        font-weight: variables.$fontWeight-bold;
        margin: 0;
    }
}
.accordion__content {
    padding: 0 20px 20px;
    font-size: utilities.pxtorem(19px);
    @include utilities.breakpoint(variables.$bp-mw) {
        padding: 0 50px 40px;
    }
}

/******************************** 
** Search Page Accordion Fixes 
********************************/
.listing-item.accordion__item {
    padding: 0;
    display: block;
}


/*************************************** 
** Search Page Accordion Search filter 
***************************************/

.search-faq {
    padding: 60px 0;
}
.search-faq__top {
    margin-bottom: 30px;
}

.search-faq__input-wrapper {
    position: relative;
}
.search-faq__input {
    width: 100%;
    border-radius: 5px;
}

.search-faq__button {
    @include utilities.resetBtn;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover, &:focus {
        .search-faq__icon {
            fill: variables.$color-purple;
            transform: scale(1.1);
        }
    }
}

.search-faq__icon {
    width: 25px;
    height: 25px;
}

.search-faq__title{
    font-size: utilities.pxtorem(45px);	
    margin-bottom: 20px;  
} 