@use "../../utilities";
@use "../../variables";

/* ================================================================
	Mobile wallet Banner
================================================================ */

.mobile-wallet-section {
	background: url(../images/mobile-wallet-bg.png) no-repeat;
	background-size: cover;
	background-position: center;
	overflow: hidden;
	align-items: center;
	padding: 60px 0 0;

	@include utilities.breakpoint(variables.$bp-mw) {
		padding: 180px 0;
		background-size: 100% 100%;
	}

	
	.title .curve {
		background-image: url(../images/titles/title-mobile-wallet.svg);
	}
}
.mobile-wallet__wrap {
	position: relative;
	z-index: 1;
	@include utilities.breakpoint(variables.$bp-mw) {
		display: flex;
		align-items: center;
	}
}
.mobile-wallet__figure {
	flex: 1;
	padding: 0 variables.$grid-gutter;
	margin-bottom: 80px;
	text-align: center;
	@include utilities.breakpoint(variables.$bp-mw) {
		width: 30%;
		max-width: 570px;
		margin-bottom: 0;	
	}
}
.mobile-wallet__img {
	&.regular {
		border-radius: 0;
	}
}
.mobile-wallet__right {
	flex: 1;
	max-width: 500px;
	margin: auto;
	@include utilities.breakpoint(variables.$bp-mw) {
		max-width: 100%;
		margin-left: 60px;
	}
	@include utilities.breakpoint(variables.$bp-xl) {
		width: 70%;
		margin-left: 170px;
	}
}

.mobile-wallet__desc {
	font-size: utilities.pxtorem(20px);
	@include utilities.breakpoint(variables.$bp-mw) {
		font-size: utilities.pxtorem(24px);	
	}
}
.mobile-wallet__features {
	margin: 40px 0;
	@include utilities.breakpoint(variables.$bp-mw) {
		display: flex;
		margin: 60px 0;
		flex-wrap: wrap;
	}
	@include utilities.breakpoint(variables.$bp-xxl) {
		margin: 80px 0;		
	}
}
.mobile-wallet__feature {
	flex: 1;
	margin-bottom: 60px;
	
	@include utilities.breakpoint(variables.$bp-mw) {
		padding-right: 20px;
		flex: 1 50%;
	}
}
.mobile-wallet__feature-icon {
	margin-bottom: 40px;
}
svg.mobile-wallet__feature-icon {
	width: 96px;
	height: 96px;
	border-radius: 50%;
}
.mobile-wallet__feature-title {
	font-size: utilities.pxtorem(30px);
	margin-bottom: 10px;

	@include utilities.breakpoint(variables.$bp-mw) {
		margin-bottom: 20px;
	}
}
.mobile-wallet__feature-desc {
	font-size: utilities.pxtorem(20px);
}
.mobile-wallet__stores {
	margin-top: 30px;
	
}
.mobile-wallet__store-link {
	display: inline-block;
    transition: variables.$trans;
    transform: scale(1);
	margin-bottom: 20px;
    &:hover, 
    &:active, 
    &:focus {        
		transform: scale(1.02);
    }
	&:not(:last-child) {
		margin-right: variables.$grid-gutter;
	}
}