@use "../../utilities";
@use "../../variables";

/* ================================================================
   Filters
   ================================================================ */
.filters-top__text {
    display: flex;
    align-items: center;
    font-size: utilities.pxtorem(19px);
    padding:15px;        
    
    @include utilities.breakpoint(variables.$bp-mw) {
        padding:0;        
    }

    svg {
        width: 25px;
        height: 25px;
        margin-right: 5px;
    }
}
.filters {
    margin:0 0 1rem;
    padding:15px;
}

.filter {
    margin-bottom: 15px;
    position: relative;

    @include utilities.breakpoint(variables.$bp-mw) {
        margin-bottom: 0;
    }

    .button {
        width:100%;
        white-space: nowrap;
    }

    &:last-child {
        margin-bottom:0;
    }
}

.filter__label {
    display:block;
    margin:0 0 3px;
    white-space: nowrap;
}
.filter__label--date {
    font-size:.875em;
    padding:0 5px 0 0;
}

.filter__select {
    width:100%;
    cursor: pointer;
    border-radius: 5px;
}

.filter__input {
    width:100%;
    height: 60px;
    border-radius: 5px;
}
.filter__input--date {
    width:70px;
}

.filter__fieldset {
    margin:0 0 15px;
}

.filter__legend {
    display:block;
    margin:0 0 3px;
    width:100%;
}

.filter__fieldset-content {
    display:flex;
}

.filter__inner-field {
    padding:0 10px 0 0;
}

.filter__search-content {
    position:relative;
}

.filter__search-button {
    position:absolute;
    background:none;
    border:0;
    padding:0;
    width:20px;
    height:20px;
    top:50%;
    right:10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: variables.$color-primary;

    &:hover,
    &:focus {
        color: variables.$color-secondary;
    }

    svg {
        fill: currentColor;
        width:100%;
        height:100%;
        transition: variables.$trans;
    }
}

.filter--nolabel {
    &::before {
        @extend .filter__label;
        content: '\a0'; // non breaking space
    }
}

@media (min-width: variables.$bp-mw) {
    .filters {
        display:flex;
        padding:15px 5px;
        justify-content: flex-start;
    }

    .filters--stacked {
        display: block;

        .filter {
            margin-bottom: 10px;
        }
    }

    .filter {
        margin-left: 10px;
        margin-right: 10px;
        width:100%;
        max-width: 220px;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }

    .filter--date {
        display:flex;
    }

    .filter__fieldset {
        margin:0;
    }

    .filter__fieldset-content {
        justify-content: space-between;
    }

    .filter__inner-field {
        display:flex;
        align-items:center;
        padding:0 10px 0 0;
    }
}
.filter--page-search {
    max-width: 100%
}
.filter__toggle {
    @extend .filter__select;
    background-color: variables.$color-invert;
    text-align: left;
    font-family: variables.$font-primary;
    font-size: utilities.pxtorem(19px);
    height: 60px;
    padding: 5px 20px;
    border: 1px solid variables.$color-base;    
    position: relative;
    padding-right: 2em;
    transition: variables.$trans;

    &[data-expander-toggle] {
        &::after {
            border-style: solid;
            border-width: 0.15em 0.15em 0 0;
            content: '';
            display: inline-block;
            height: 0.5em;
            width: 0.5em;
            right: 1em;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            transform: rotate(135deg);
            vertical-align: top;
        }
        &.active {   
            border: 1px solid variables.$color-primary;            
            &::after {
                transform: rotate(315deg);
            }
        }
    }

    &:hover, 
    &:active, 
    &:focus {
        border-color: variables.$color-primary;
    }
}
.filter__select-custom {
    @include utilities.resetList;
    border: 1px solid variables.$color-primary;
    margin-top: -1px;
    width: 100%;
    background-color: variables.$color-invert;
    z-index: 1;

    @include utilities.breakpoint(variables.$bp-mw) {
        position: absolute;
    }

    a {
        text-decoration: none;
        display: block;
        padding: 10px 20px;
        font-size: utilities.pxtorem(19px);

        &:hover, 
        &:active, 
        &:focus {
            background-color: variables.$color-gray;
        }
    }
}