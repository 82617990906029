@use "../../utilities";
@use "../../variables";

.breadcrumbs {
    display: none;
    @include utilities.resetList;
    @include utilities.x-rem(font-size, 15px);
    padding: variables.$grid-gutter 0 0;
}

.breadcrumbs__item {
    position:relative;
    display: inline-block;
    padding-right: 30px;

    &::after {
        content: "";
        display: block;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
        height: 12px;
        background: #757575;
    }

    &:last-child {
        padding: 0;

        &::after { content: none; }
    }
}

.breadcrumbs__link {
    display: inline-block;
}

.breadcrumbs__active {
    font-weight: bold;
}

@media (min-width: variables.$bp-mw) {
    .breadcrumbs {
        display: block;
    }
}