@use "sass:math";
@use "../../utilities";
@use "../../variables";

/*================================================================
            MAIN NAVIGATION STYLES
================================================================*/

.main-navigation {
    position: relative;
    padding-top: 20px;
    background-color: #fff;

    //Nav Defaults

    ul {
        list-style: none;
        padding:0;
        margin:0;
    }
    li {

    }
    a,
    button {
        display:block;
        width:100%;
        border:0;
        padding:10px;
        color:#000;
        text-align: left;
        background:none;
        cursor:pointer;
        text-decoration: none;
        font-family: variables.$font-primary;
        line-height: 1.2;

        &:hover,
        &:focus {
        }

        &[data-expander-toggle] {
            position: relative;
            padding-right: 35px;

            // Arrows
            &:after {
                border-style: solid;
                border-width: 2px 2px 0 0;
                content: '';
                display: inline-block;
                height: 8px;
                width: 8px;
                right: 16px;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                transform: rotate(135deg);
                vertical-align: top;
                border-color: variables.$color-base !important;
            }

            &.active {
                &:after {
                    transform: rotate(-45deg);
                }
            }
        }

    }

    .main-navigation__heading {
        font-weight:bold;
    }

    // 1st Level
    // ********************************************* //
    > ul,
    .main-navigation-mega > ul {
        background:#fff;

        > li {

            > a,
            > button {
                font-weight: variables.$fontWeight-bold;
                font-size: utilities.pxtorem(19px);
                padding-left: variables.$grid-gutter;
                padding-right: variables.$grid-gutter;

                &:hover,
                &:focus {
                    
                }

                .emphasis {
                    color: variables.$color-purple;
                }
            }

            // 2nd Level
            // ********************************************* //
            > ul {
                background:#fbfbfb;

                > li {

                    > a,
                    > button {
                        padding-left: variables.$grid-gutter;
                        padding-right: variables.$grid-gutter;

                        &:hover,
                        &:focus {
                            
                        }
                    }
        
                    // 3rd Level
                    // ********************************************* //
                    
                    > ul {
                        background: variables.$color-gray;
    
                        > li {
    
                            > a,
                            > button {
                                padding-left: variables.$grid-gutter;
                                padding-right: variables.$grid-gutter;
    
                                &:hover,
                                &:focus {
                                    text-decoration: none;
                                }
                            }       
                            
                            // 4th Level
                            // ********************************************* //
                            
                            > ul {
                                background: variables.$color-gray;
            
                                > li {
            
                                    > a,
                                    > button {
                                        padding-left: variables.$grid-gutter;
                                        padding-right: variables.$grid-gutter;
            
                                        &:hover,
                                        &:focus {
                                            text-decoration: none;
                                        }
                                    }                                
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include utilities.breakpoint(variables.$bp-mw) {
        position: static;
        padding: 0;
        background-color: transparent;

        //Nav Defaults

        ul {

        }
        li {

        }
        a,
        button {


            &:hover,
            &:focus {

            }
        }

        // 1st Level
        // ********************************************* //
        > ul {
            display:flex;
            justify-content: space-between;
            position: static;
            background:none;
            align-items: center;

            > li {
                position: relative;
                margin-right: 10px;

                &.active {
                    &::before { 
                        content: ''; 
                        display: block;
                        position: absolute;
                        margin: auto;
                        width: 90px;
                        height: 30px;
                        background: url(../images/menu-active.svg) no-repeat;
                        bottom: -25px;
                        transition: variables.$trans;
                    }
                }


                &[data-type="mega"] {
                    position: static;
                }

                > a,
                > button {
                    background:none;
                    color:#333333;
                    font-size: utilities.pxtorem(14px);
                    padding: 10px 5px;

                    &:hover,
                    &:focus {

                    }
                    &[data-expander-toggle] {
                        position: relative;
            
                        // Arrows
                        &:after {
                            border-color: #333 transparent transparent transparent;
                        }
            
                        &.active {
                            &:after {
                                border-color: transparent transparent #333  transparent;
                            }
                        }
                    }
                }

                // 2nd Level
                // ********************************************* //
                > ul {
                    position:absolute;
                    width:auto;
                    top: calc(100% + 15px);
                    background:#fff;
                    border-top: 2px solid variables.$color-primary;
                    padding: 20px;
                    width: calc(100% + 500px);
                    left: calc(-250px);
                    border-radius: 20px;
                    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.05);

                    > li {

                        > a,
                        > button {
                            font-size: utilities.pxtorem(24px);
                            font-weight: variables.$fontWeight-bold;
                            padding: 15px 40px;

                            &:hover,
                            &:focus {
                                font-weight: variables.$fontWeight-extrabold;
                            }
                        }
            
                        // 3rd Level
                        // ********************************************* //
                        
                        > ul {
                            background:#fff;
                            padding: 0 20px 10px;
        
                            > li {
        
                                > a,
                                > button {
                                    font-size: utilities.pxtorem(20px);
                                    font-weight: variables.$fontWeight-bold;
        
                                    &:hover,
                                    &:focus {
                                        text-decoration: none;
                                        font-weight: variables.$fontWeight-extrabold;
                                    }
                                }       
                                
                                // 4th Level
                                // ********************************************* //
                                
                                > ul {
                                    background:#fff;
                
                                    > li {
                
                                        > a,
                                        > button {
                
                                            &:hover,
                                            &:focus {
                                                text-decoration: none;
                                            }
                                        }                                
                                    }
                                }
                            }
                        }
                    }
                }
            }
            // Mega
            // ********************************************* //
            [data-nav-type="mega"] {
                position: static;

                .main-navigation-mega {
                    padding:0;
                    position: absolute;
                    top: calc(100% + 15px);
                    left:0;
                    right: 0;
                    margin: auto;
                    width:100%;
                    background:#fff;
                    box-shadow: 0 0 10px 10px rgba(0,0,0,.05);
                //    max-width: $container-size-medium;
                    border-top: 2px solid variables.$color-primary;
                    border-radius: 20px;

                    ul {
                        animation: none;
                        background:none;
                    }

                    > ul {
                        display:flex;
                        padding: 0 60px;

                        // Mega 1st Level
                        // ********************************************* //
                        > li {
                            &.mega-col--left,
                            &.mega-col--right {
                                flex: 1;
                                margin: 40px 0;
                            }                            
                            &.mega-col--left {
                            }                          
                            &.mega-col--right {
                                border-left: 1px solid variables.$color-dark-gray;
                                padding-left: 60px;
                            }
                            &.mega-col--middle {
                                background-color: variables.$color-gray;
                                margin-right: -1px;
                                position: relative;
                            }

                            > a,
                            > button {

                                &:hover,
                                &:focus {
                                    
                                }
                            }       

                            // Mega 2nd Level
                            // ********************************************* //
                            > ul {
                                
                                > li {
            
                                    > a,
                                    > button {
                                        color: #000;
                                        font-size: utilities.pxtorem(26px);
                                        font-weight: variables.$fontWeight-bold;
                                        padding: 22px 40px;

                                        &:hover,
                                        &:focus {
                                            font-weight: variables.$fontWeight-extrabold;                                          
                                        }
                                        &.active {
                                            background-color: variables.$color-gray;
                                            border-radius: 50px 0 0 50px;
                                        }
                                        
                                        &[data-expander] {
                                            position: relative;
                                            padding-right: 35px;

                                            // Arrows
                                            &:after {
                                                border-style: solid;
                                                border-width: 2px 2px 0 0;
                                                content: '';
                                                display: inline-block;
                                                height: 8px;
                                                width: 8px;
                                                right: 0;
                                                position: relative;
                                                top: 12px;
                                                margin: auto;
                                                transform: rotate(45deg);
                                                vertical-align: top;
                                                border-color: variables.$color-base !important;
                                            }

                                            &.active {
                                                &:after {
                                                    right: -5px;
                                                }
                                            }
                                        }

                                    }     
                                    
                                    // Mega 3rd Level
                                    // ********************************************* //
                                    > ul {
                                        display: none;
                                        padding: 65px 40px;
                                        
                                        > li {
                                            width: 280px;
                                            max-width: 100%;
                    
                                            > a,
                                            > button {
                                                color: #000;
                                                font-size: utilities.pxtorem(20px);
                                                padding: 10px 40px;

                                                &:hover,
                                                &:focus {
                                                    font-weight: variables.$fontWeight-bold;                                                  
                                                }
                                            }                                
                                        }
                                    }
                                }
                            }
                        }
                    }
                    
                }
            }
        }
    }

    .third-lvl-desktop.active ul {
        @include utilities.breakpoint(variables.$bp-mw) {
            display: block !important;
            animation: none;
        }
    }
    
    @include utilities.breakpoint(1400px) {        
        // Mega
        // ********************************************* //
        [data-nav-type="mega"] {
            .main-navigation-mega {
             //   max-width: 1180px !important;
                &:has(.third-lvl-desktop.active) {
             //       max-width: 90% !important;
                }
                
                > ul {
                    padding: 0 100px !important;

                    // Mega 1st Level
                    // ********************************************* //
                    > li {
                        &.mega-col--left,
                        &.mega-col--right {
                            flex: 1;
                            margin: 60px 0;
                        }                            
                        &.mega-col--left {
                        }                          
                        &.mega-col--right {
                            border-left: 1px solid variables.$color-dark-gray;
                            padding-left: 100px !important;
                        }
                        &.mega-col--middle {
                            background-color: variables.$color-gray;
                        }
                    }
                }
            }
        }
    }
    @include utilities.breakpoint(variables.$bp-l) {      
        // 1st Level
        // ********************************************* //
        > ul {
            > li {
                margin-right: 30px;
                > a,
                > button {
                    font-size: utilities.pxtorem(19px);
                }
            }
        }
    }
}

.mega-content {
    padding: variables.$grid-gutter;
    border-bottom: 1px solid variables.$color-dark-gray;
    @include utilities.breakpoint(variables.$bp-mw) {
        border-bottom: none;
        padding: 0;
    }
}

.mega-content__figure {
    margin-bottom: 10px;
}
.mega-content__img {
    display:block;
    background: no-repeat center center / cover;
    border-radius: 20px;
    &::after { 
        content: ''; 
        display: block; 
        padding-top: math.percentage(1*0.5); 
    }
}
.mega-content__heading {
    font-style: utilities.pxtorem(24px);
    margin-bottom: 20px;
}
.mega-content__btn {
    background-color: variables.$color-purple !important;
    color: #fff !important;
    padding: 20px 45px !important;
    width: fit-content  !important;
}