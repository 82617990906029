@use "../../utilities";
@use "../../variables";

/* ================================================================
   Calculators Section
================================================================ */
.calculators-section {
   position: relative;
   padding: 50px 0;
   @include utilities.breakpoint(variables.$bp-l) {
      padding: 150px 40px;
      display: flex;
      & > * {
         flex: 1;
      }
   }

   &::before,
   &::after { 
      content: ''; 
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 10px;
      background-image: radial-gradient(circle at 5px 5px, variables.$color-secondary 3px, transparent 0);
      background-size: 30px 10px;
      background-repeat: repeat-x;
   }
   &::before {
      top: 0;
   }
   &::after {
      bottom: 0;
   }
}
.calculators-section-col-left {
   margin-bottom: 40px;
   @include utilities.breakpoint(variables.$bp-l) {
      margin-bottom: 0;
      padding: 0 variables.$grid-gutter;
      .calculators-section__box {
         max-width: 600px;
      }
   }
}
.calculators-section-col-right {   
   @include utilities.breakpoint(variables.$bp-mw) {
      display: flex;
      & > * {
         flex: 1;
      }
   }
}