@use "sass:math";
@use "../../utilities";
@use "../../variables";

.card-section {
    padding: 0;
    
    .title {
        margin: 0 -#{variables.$grid-gutter} 40px;
        padding-left: variables.$grid-gutter;
        text-align: left;

        @include utilities.breakpoint(variables.$bp-mw) {
            text-align: center;
            margin: 0 -#{variables.$grid-gutter} 50px;
        }
        @include utilities.breakpoint(variables.$bp-xxl) {            
            margin: 0 -#{variables.$grid-gutter} 80px;
        }
    }
    .title .curve {
        background-image: url(../images/titles/title-cards.svg);
    }
}

.card-listing {
    @include utilities.resetList;
    justify-content: space-between;
    margin: 0;
	border-bottom: 1px solid variables.$color-dark-gray ;

    @include utilities.breakpoint(variables.$bp-mw) {
        display: flex;
        margin: 0 0 70px;
        border: none
    }
}

.card-item {
    @include utilities.x-rem(font-size, 14px);
    padding: 0 10px;
    margin: 0 auto 60px;
    line-height: 1.4;
    text-align: center;
    position: relative;

    @include utilities.breakpoint(variables.$bp-mw) {
        flex: 1 0 0px;
        width: auto;
        margin: 0;
        padding: 0 40px;
    }

    @include utilities.breakpoint(variables.$bp-mw) {
        &:not(:last-child) {
            .card-item__figure {
                position: relative;
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    margin: auto;
                    top: 0;
                    bottom: 0;
                    background-image: url(../images/cards-dots.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                    right: -8vw;
                    width: 7vw;
                    max-width: 99px;
                    height: 9px;
                }
            }
        }
    }

    @include utilities.breakpoint(variables.$container-size-em) {
        &:not(:last-child) {
            .card-item__figure {
                &::after {
                    right: -126px;
                    width: 99px;
                    height: 9px;
                }
            }
        }
    }

    
    .card-item__figure {
        &::before { 
            content: ''; 
            display: block; 
            width: 25px;
            height: 25px;
            border-radius: 50%;
            position: absolute;
            z-index: 2;
        }
    }

    &:nth-child(1) {
        .card-item__figure {
            &::before { 
                background-color: variables.$color-purple;   
                top: 2%;
                left: 24%;             
            }
        }
    }
    &:nth-child(2) {
        .card-item__figure {
            &::before { 
                background-color: variables.$color-secondary;   
                bottom: 2%;
                left: 24%;             
            }
        }
    }
    &:nth-child(3) {
        .card-item__figure {
            &::before { 
                background-color: variables.$color-primary;   
                top: 30%;
                right: 0%;             
            }
        }
    }
    &:nth-child(4) {
        .card-item__figure {
            &::before { 
                background-color: variables.$color-purple;
                top: 2%;
                left: 24%;          
            }
        }
    }
}

.row .col-md-6,
.row .col-md-4,
.row .col-md-3 {
    &:nth-child(1) {
        .card-item__figure {
            &::before { 
                background-color: variables.$color-purple;
                top: 2%;
                left: 24%;
            }
        }
    }
    &:nth-child(2) {
        .card-item__figure {
            &::before { 
                background-color: variables.$color-secondary;
                bottom: 2%;
                left: 24%;
                top: inherit;
            }
        }
    }
    &:nth-child(3) {
        .card-item__figure {
            &::before { 
                background-color: variables.$color-primary;
                top: 30%;
                right: 0%;
                left: inherit;
            }
        }
    }
    &:nth-child(4) {
        .card-item__figure {
            &::before { 
                background-color: variables.$color-purple;
                top: 2%;
                left: 24%;
            }
        }
    }
}

.card-item__inner {
    display: block;

    a {
        color: currentColor;
        text-decoration: none;
    }
}
.card-item__figure {
    max-width: 250px;
    margin: auto;
    position: relative;
}
.card-item__img {
    @include utilities.lazy-fade-in();
    background: no-repeat center / cover;
    position: relative;
    border-radius: 50%;
    margin: auto auto 30px;

    &::after {
        content: "";
        display: block;
        padding-top: math.percentage(math.div(1, 1));
    }
}

.card-item__title {
    transition: variables.$trans;
    margin: 0;
    font-size: utilities.pxtorem(30px);
    margin-bottom: variables.$grid-gutter;

    @include utilities.breakpoint(variables.$bp-mw) {
        font-size: utilities.pxtorem(30px);
    }
}

.card-item__meta {
    display: block;
    margin-bottom: variables.$grid-gutter;
    font-size: utilities.pxtorem(18px);

    ul,
    ol {
        text-align: left;
    }
}
.card-item .btn--link {
    padding-right: 0;
    &::before { 
        content: ''; 
        display: block; 
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }
}