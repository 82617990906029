@use "../../utilities";
@use "../../variables";

/* ================================================================
	Resources
================================================================ */

.resources-section {
    align-items: center;

	&--image-bg {
		background-image: url(../images/trees-bg.jpg);
		background-repeat: no-repeat;
		background-size: 200%;
		background-position: bottom;
		padding: 40px 0 200px;
		@include utilities.breakpoint(variables.$bp-mw) {
			padding: 50px 0 600px;
			background-size: cover;
		}
		@include utilities.breakpoint(variables.$bp-xxl) {
			padding: 80px 0 600px;			
		}
	}
}
.resources {
	.title .curve {
		background-image: url(../images/titles/title-resources.svg);
	}
	.title.curve {
		&::after { 
			background-image: url(../images/titles/title-resources.svg);
		}
	}
}
.resources__grid {

	@include utilities.breakpoint(variables.$bp-mw) {
		display: flex;
		flex-wrap: wrap;
	}
}
.resources__col {
	margin-bottom: 60px;
	@include utilities.breakpoint(variables.$bp-mw) {
		width: 50%;
		padding-right: 60px;
	}
	@include utilities.breakpoint(variables.$bp-l) {
		width: 25%;
	}
	@include utilities.breakpoint(variables.$bp-xxl) {
		margin-bottom: 100px;		
	}
}
.resources__col--title {
	@include utilities.breakpoint(variables.$bp-mw) {
		width: 50%;
	}
}
.resources__col--full-width {
	width: 100%;
}
.resources__col--full-width.resources__col--title {
	@include utilities.breakpoint(variables.$bp-mw) {
		display: flex;
		align-items: center;

		.resources__btn {
			margin-top: 0;
			margin-left: auto;
		}
	}
}
.resources__category {
	text-transform: uppercase;
	font-size: utilities.pxtorem(16px);
	font-weight: variables.$fontWeight-extrabold;
	padding-top: 30px;
}
.resources__title {
	margin-top: 30px;
}
.resources__col--item {
	&:nth-child(1n){
	   .resources__col-inner {
		   border-top: 4px solid variables.$color-purple;
	   }
	}
	&:nth-child(2n){
		.resources__col-inner {
			border-top: 4px solid variables.$color-primary;
		}
	}
	&:nth-child(3n){
		.resources__col-inner {
			border-top: 4px solid variables.$color-secondary;
		}
	}
	&:nth-child(4n){
		.resources__col-inner {
			border-top: 4px solid variables.$color-orange;
		}
	}
}
.resources__desc {
	font-size: utilities.pxtorem(20px);
}
.resources__btn {
	padding: 0 2rem 0 0;
	margin-top: variables.$grid-gutter;
}

.resources--interior {
	.title {
		max-width: 660px;
		@include utilities.fluid-type(variables.$bp-s, variables.$container-size-em, 44px, 60px);
	}
}