@use "../globals/forms";
@use "../variables";

.mas-input.input-validation-error {
    background-color: #FFCCCC !important;
    border-color: forms.$error-color;
}

.bt-maximum {
    margin: 10px 0;
}

#balance-trans-explanation {
    max-width: 800px;
}

#exceeds-max-msg {
    color: forms.$error-color;
    margin-top: 10px;
    font-size: 1.1em;
}

.review-edit-btn {
    margin: 15px 0 5px 0;
}

.review-row {
    margin-left: -(variables.$grid-gutter-em); 
    margin-right: -(variables.$grid-gutter-em); 
    display:flex; 
    flex-direction: column;
    flex-wrap: wrap;
    gap: variables.$grid-gutter-em;
    padding: variables.$grid-gutter-em;
    @media (max-width: variables.$bp-m) {
        padding: 0 10px;
    }
}

.review-card {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    border-top: 4px solid variables.$color-cyan; 
    background-color: #fff;
    padding: 10px; 
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.06);
    @media (min-width: variables.$bp-m) {
        grid-template-columns: repeat(6, 1fr);
    }
}

.review-personal {
    p, h3, button {
        grid-column: span 1;
    }
    @media (min-width: variables.$bp-m) {
        h3, button, .review-email {
            grid-column: span 6;
        }
        p {
            grid-column: span 3;
        }
    }
}

.review-security {
    p, h3, button {
        grid-column: span 1;
    }
    @media (min-width: variables.$bp-m) {
        h3, button {
            grid-column: span 6;
        }
        p {
            grid-column: span 3;
        }
    }
}

.review-employment {
    p, h3, button {
        grid-column: span 1;
    }
    @media (min-width: variables.$bp-m) {
        h3, button {
            grid-column: span 6;
        }
        p {
            grid-column: span 3;
        }
    }
}

.review-transfers {
    .review-transfer-index {
        font-size: 1.33em;
        font-weight: variables.$fontWeight-extrabold;
        margin: 0 0 20px 0;
    }
    .review-transfers-totals {
        max-width: 450px;
        font-family: Arial, sans-serif;
        margin-bottom: variables.$grid-gutter-em;
    
        .review-totals-row {
            display: flex;
            justify-content: space-between;
            padding-top: 3px;
    
            &.total {
                font-weight: bold;
                border-top: 1px solid variables.$color-darker-gray;
                margin-top: 9px;
            }
    
            &--label {
                flex: 3;
                text-align: left;
            }
    
            &--value {
                flex: 2;
                text-align: right;
            }
        }
    }
    p, 
    h3, 
    h4, 
    button, 
    hr, 
    .review-transfers-totals {
        grid-column: span 1;
    }
    @media (min-width: variables.$bp-m) {
        p {
            grid-column: span 2;
        }
        h3,
        h4,
        button,
        hr,
        .review-transfers-totals {
            grid-column: span 6;
        }
    }
}