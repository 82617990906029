@use "sass:color";
@use "../globals/tables";
@use "../variables";

.cdfi-form-section {
    padding-bottom: 15px;
    
    &__subheader {
        font-size: 1.5em;
        margin: 15px 0 15px 0;
    }
    
    .balance-transfer-form {
        margin-bottom: 30px;
    }

    // For balance transfer dropdown
    details > summary {
        background-color: variables.$color-cyan;
        border-radius: tables.$table-border-radius;
        border: variables.$color-cyan;
        color: variables.$color-invert;
        font-weight: variables.$fontWeight-semibold;
        cursor: pointer;
        padding: 0.75em;
        width: 100%;
        &:hover {
            background-color: color.adjust(variables.$color-cyan, $lightness: 5%);
        }
    }
}

.cdfi-selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
}
.cdfi-back-container {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 3em;
}
.cdfi-app-type-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.cdfi-app-btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    button.btn {
        max-width: 500px;
        width: 100%;
        margin: 0.25em 0;
    }
}

.cdfi-app-header-container {
    margin-bottom: 2.75em;
    h2 {
        margin-bottom: 1rem;
    }
    h4 {
        margin: 0em;
    }
}

.cdfi-next-btn {
    padding-left: 25px;
}

.cdfi-section {
    display: none;
}

