@use "../../utilities";
@use "../../variables";

.site-header {
    position: relative;
    padding: 0;
    background: variables.$color-invert;
    z-index: 10;
}
.is-home {
    .site-header {
        background-color: transparent;
    }
    .header-top {
        border-bottom: none;
    }
    .header-col {
        border-bottom: none;
    }
}

.header-col {
    position: static;
    border-bottom: 1px solid #D7D7D7;
}

.header-top {
    display: flex; 
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 10px 0 30px;
    transition: variables.$trans;
    @include utilities.breakpoint(variables.$bp-mw) {
        padding: 0;
        margin: 80px 0 30px;
        position: relative;
        justify-content: space-between;
    }
}

.header-logo {
    margin: 0;
    font-size: 0;
    max-width: 180px;
    margin-right: auto;
    transition: variables.$trans;

    @include utilities.breakpoint(variables.$bp-mw) {
        max-width: 265px;
        margin: 0 20px 0 0;
        flex: 1 1 auto;
    }

    a {
        display: inline-block;
    }
}

.header-mobile-btn.hamburger {
    margin-right: 20px;
    margin-left: 20px;
}

.culture-selector {
    position: relative;
    margin:0 20px 0 0;
}
.culture-selector__button {
    @extend .button !optional;
    display:flex;
    align-items: center;

    &:after {
        content:'';
        border-top:5px solid variables.$color-invert;
        border-left:4px solid transparent;
        border-right:4px solid transparent;
        margin:0 0 0 7px;
    }
}
.culture-selector__list {
    @include utilities.resetList;
    position: absolute;
    top:100%;
    left:0;
    border:1px solid variables.$color-primary;
    background:#fff;
    z-index: 1001;
    display:none;
}
.culture-selector__item {

}
.culture-selector__link {
    text-decoration: none;
    display: block;
    padding:7px 15px;

    &:hover,
    &:focus {
        background:variables.$color-primary;
        color: variables.$color-invert;
        text-decoration: none;
    }
}

.header-search-trigger {
    @include utilities.resetBtn;
    display: block;
    width: 28px;
    height: 28px;
    font-size: 0;
    flex: 0 0 auto;
    margin-right: 10px;

    @include utilities.breakpoint(variables.$bp-mw) {
    }

    &:hover,
    &:focus {
    }

    svg {
        width: 100%;
        height: 100%;
        fill: currentColor;
    }
}

.header-search,
.header-search[data-expander-target]  {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 100;
    padding: 2px;
    border-radius: 50px;
    background: linear-gradient(90deg, variables.$color-primary 29.37%, variables.$color-orange 45%, variables.$color-purple 58.91%, variables.$color-secondary 76.08%);
    z-index: 2;

    &.active {
        display: block;
    }

    &::before { 
        content: ''; 
        display: block; 
        position: absolute;
        top: -8px;
        right: 35px;
        width: 40px;
        height: 40px;
        border-radius: 10px;
        background-color: variables.$color-secondary;
        transform: rotate(45deg);
        z-index: -1;
    }
    
    @include utilities.breakpoint(variables.$bp-mw) {
        background-color: #fff;
        max-width: 1160px;
        left: inherit;
        right: -30px;  
        top: calc(100% + 14px);      
    }
}

.header-search-input {
    display: block;
    width: 100%;
    background: none;
    padding: 10px variables.$grid-gutter-em;
    font-size: utilities.pxtorem(19px);
    margin-left: auto;
    border-radius: 50px;
    background-color: #fff;
    border-color: transparent;

    @include utilities.placeholder {
      opacity: 1;
    }
    &:hover, 
    &:active, 
    &:focus {
        border-color: transparent;
    }
}

.header-search-submit {
    @include utilities.resetBtn;
    position: absolute;
    top: 50%;
    right: variables.$grid-gutter-em;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    color: variables.$color-base;

    &:hover,
    &:focus {
        opacity: 0.75;
    }

    svg {
        width: 100%;
        height: 100%;
        fill: variables.$color-purple;
    }
}

.header-bottom{
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 100;
    @include utilities.breakpoint(variables.$bp-mw) {        
        position: static;
        display: block;
        top:auto;
        width:auto;
        margin-left: auto;
    }
}
.header-utility {
    background-color: variables.$color-invert;
    position: relative;
    z-index: 1;
}
.header-utility__inner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    @include utilities.breakpoint(variables.$bp-mw) {
        border-bottom: 1px solid #D3D3D3;
        margin-bottom: 0;
    }
}

.header-utility-tabs {
    width: 100%;
    @include utilities.breakpoint(variables.$bp-mw) {
        width: auto;
    }
}
.header-utility-tabs__list {
    @include utilities.resetList;
    display: flex;
    height: 100%;
    position: relative;
    z-index: 1;
    width: 100%;

    @include utilities.breakpoint(variables.$bp-mw) {
        width: auto;
        overflow: hidden;
        margin-top: 1px;
    }
}

.header-utility-tabs__item {
    height: 100%;
    position: relative;
    flex: 1;
    background-color: #EDEDED;

    @include utilities.breakpoint(variables.$bp-mw) {
        margin-left: -20px;
        padding-left: 20px;
        flex: auto;
        border: 1px solid #D8D8D8;
        border-radius: 0 20px 0 0;
        border: 1px solid #D8D8D8;
    }
}

.header-utility-tabs__item{
    &:nth-child(1) {
        z-index: 10;

        @include utilities.breakpoint(variables.$bp-mw) {
            margin-left: 0;
            padding-left: 0;
        }
    }
    &:nth-child(2) {
        z-index: 9;
    }
    &:nth-child(3) {
        z-index: 8;
    }
    &:last-child {
        @include utilities.breakpoint(variables.$bp-mw) {
            margin-right: 20px;
        }
    }
}
.header-utility-tabs__link {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 15px;
    text-decoration: none;
    margin-top: 1px;
    font-weight: variables.$fontWeight-extrabold;
    justify-content: center;
    text-align: center;
    line-height: 1;

    @include utilities.breakpoint(variables.$bp-mw) {
        justify-content: flex-start;
        padding: 0 30px;
    }
}
.header-utility-tabs__item.active {
    background-color: variables.$color-secondary;
    height: 60px;
    margin-bottom: -15px;
    border-radius: 0 0 20px 20px;

    &:first-child {
        border-radius: 0 0 20px 0;
    }

    &:last-child {
        border-radius: 0 0 0 20px;
    }

    @include utilities.breakpoint(variables.$bp-mw) {
        background-color: #fff;
        border-left: none;
        border-bottom: none;
        box-shadow: 0px 0px 10px 6px rgba(0,0,0,.2);   
        height: auto;
        margin-bottom: 0;
        border-radius: 0 20px 0 0;
        position: relative;

        &::before {
            @include utilities.breakpoint(1420px) {
                content: '';
                position: absolute;
                left: 32px;
                top: 18px;
                width: 10px;
                height: 10px;
                background-color: variables.$color-secondary;
                border-radius: 50%;
            }
        }
    
        &:first-child {
            border-radius: 0 20px 0 0;
            &::before {
                left: 14px;
            }
        }
        &:nth-child(2) {
            &::before {
                background-color: variables.$color-purple;
            }
        }    
        &:last-child {
            border-radius: 0 20px 0 0;
        }
    
    }
}

/******************************** 
** Utility Nav 
********************************/
.utility-nav--desktop {
    display: none;
    @include utilities.breakpoint(variables.$bp-mw) {
        display: block;
    }
}
.utility-nav__list {
    @include utilities.resetList;
    display: flex;
}
.utility-nav__link {
    display: flex;
    align-items: center;
    font-size: utilities.pxtorem(14px);
    font-weight: variables.$fontWeight-semibold;
    padding: 12px 44px;
    border-left: 1px solid #D3D3D3;
    border-top: 3px solid #C5C5C5;
    text-decoration: none;

    @include utilities.breakpoint(variables.$bp-mw) {
        padding: 12px 10px;        
    }

    @include utilities.breakpoint(1420px) {
        padding: 12px 44px;        
    }

    &:hover, 
    &:active, 
    &:focus {
        background-color: rgba(0,0,0,.03);
    }

    svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
}
.utility-nav__link--login {
    background-color: variables.$color-primary;
    &:hover, 
    &:active, 
    &:focus {
        background-color: variables.$color-secondary;
    }
}


.utility-nav--mobile {
    background-color: #fff;
    padding: variables.$grid-gutter;
    @include utilities.breakpoint(variables.$bp-mw) {
        display: none;
    }
    
    .utility-nav__list {
        display: block;
        border-top: 1px solid variables.$color-dark-gray;
        padding-top: variables.$grid-gutter;
    }
    .utility-nav__link {
        border: none;
        padding-left: 0;
        padding-right: 0;
    }
    .utility-nav__link--login {
        background-color: #fff;
        &:hover, 
        &:active, 
        &:focus {
            background-color: variables.$color-gray;
        }
    }
}