@use "../../utilities";
@use "../../variables";

.interior-top {
    display: flex;
    flex-direction: column;
    margin: 0 0 15px;
}

.interior-content,
.interior-rail {
    padding: 20px 0;
}

.page-extra-container {
    display: none;
}

.page-extra-col {
    text-align: center;
    @include utilities.x-rem(font-size, 11px);
}

@media (min-width: variables.$bp-mw) {
    .interior-content,
    .interior-rail {
        padding: 50px 0;
    }

    .page-extra-container {
        display: block;
    }

    .page-extra-col {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        @include utilities.x-rem(font-size, 16px);
    }
}