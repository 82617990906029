@use "sass:math";
@use "../../utilities";
@use "../../variables";

/* ================================================================
   Leadership
================================================================ */

.leadership {
}
.leadership__list {
   @include utilities.resetList;
}
.leader {
   margin-bottom: 40px;
   @include utilities.breakpoint(variables.$bp-mw) {
      display: flex;
   }
}
.leader__figure {
   width: 100%;
   margin-bottom: 40px;

   @include utilities.breakpoint(variables.$bp-mw) {
      width: 25%;
      margin-right: 40px;
      margin-bottom: 0;
   }
}
.leader__img {
   display:block;
   background: no-repeat center center / cover;
   border-radius: 50%;

   &::after { 
       content: ''; 
       display: block; 
       padding-top: math.percentage(math.div(1, 1)); 
   }
}
.leader__content {
   flex: 1;
}
.leader__desc {
   font-size: utilities.pxtorem(20px);
}