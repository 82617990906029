@use "../../utilities";
@use "../../variables";

/* ================================================================
   Benefits
================================================================ */

.benefits {
}
.benefits__heading {
    text-align: center;
}
.benefits__list {
    @include utilities.resetList;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.benefits__item {
    margin-bottom: 35px;
    width: 100%;

    @include utilities.breakpoint(variables.$bp-mw) {
        width: 50%;
    }
    @include utilities.breakpoint(variables.$bp-l) {
        width: 25%;
    }
}
.benefits__item-container {
    background-color: variables.$color-gray;
    border-radius: 40px;
    padding: 60px;

    @include utilities.breakpoint(variables.$bp-mw) {
        margin: 0 15px;
        height: 100%;
    }
}
.benefits__title {
    font-size: utilities.pxtorem(30px);
}
.benefits__desc {
    font-size: utilities.pxtorem(20px);
}