@use "../../utilities";
@use "../../variables";

/* ================================================================
   Get Started
================================================================ */

.get-started-section {
    .title {
        margin-bottom: variables.$grid-gutter;
        text-align: left;
    
        @include utilities.breakpoint(variables.$bp-mw) {
            text-align: center;
        }
    }
    .title .curve {
        background-image: url(../images/titles/title-get-started.svg);
    }
    .title.curve {
        &::after {
            background-image: url(../images/titles/title-get-started.svg);
        }
    }
}
.get-started {
    padding: 40px variables.$grid-gutter 70px;
    background-color: variables.$color-gray;
    text-align: center;
    margin: 0 -#{variables.$grid-gutter};

    @include utilities.breakpoint(variables.$bp-mw) {
        padding: 40px 60px 70px;
        margin: 0 auto;
        border-radius: 150px;
    }

}

.get-started__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: left;
    @include utilities.breakpoint(variables.$bp-mw) {
      flex-direction: row;
      max-width: variables.$container-size-small;
      margin: auto;
      align-items: flex-end;
    }

    label,
    .get-started-secondary__label {
      font-weight: variables.$fontWeight-bold;
    }
  }
  .get-started__fieldset {
    border: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 20px;
    
    @include utilities.breakpoint(variables.$bp-mw) {
      flex-direction: row;
    }
  }
  .get-started__col-left {
    flex: 1;
    @include utilities.breakpoint(variables.$bp-mw) {
      max-width: 345px;
      margin-bottom: 0;
    }
  }
  .get-started__col-right {
    flex: 1;
  }
  .get-started__dropdown {
    width: 100%;
    border: 1px solid #979797;
    border-radius: 5px;
    padding: 10px 20px;
    min-height: 60px;
    height: 60px;
    cursor: pointer;
    background-color: variables.$color-invert;
  }
  .get-started__form--submit {
    flex-grow: 0;
    height: auto;
  }