@use "../../utilities";
@use "../../variables";

/* ================================================================
   Features
================================================================ */

.features {

}

.features-top {
    max-width: 1100px;
}
.features-main {
    @include utilities.breakpoint(variables.$bp-mw) {
        display: flex;
        margin: 0 -#{variables.$grid-gutter};
        & > * {
            flex: 1;
        }
    }
}

.features__item {
    box-shadow: 0 0 10px 10px rgba(0,0,0,0.05);
    border-radius: 40px;
    padding: 50px variables.$grid-gutter;
    margin-bottom: variables.$grid-gutter;
    @include utilities.breakpoint(variables.$bp-mw) {
        margin: variables.$grid-gutter;
    }
}
.features__item-heading {
    margin-bottom: variables.$grid-gutter;
    
    @include utilities.breakpoint(variables.$bp-mw) {
        font-size: utilities.pxtorem(44px);
        margin-bottom: 50px;
    }
}
.features__list {
    @include utilities.resetList;
}
.features__list-item {
    padding: 20px 0;
    font-size: utilities.pxtorem(20px);
    &:not(:last-child) {
        border-bottom: 1px solid variables.$color-dark-gray;
    }
    strong {
        font-weight: variables.$fontWeight-extrabold;
        font-size: utilities.pxtorem(26px);
    }
}
.features__list-feature-text {
    margin-bottom: 20px;
}
.supports--yes {
    font-weight: variables.$fontWeight-extrabold;
    color: #00562F;
}
.supports--no {
    color: #CC0000;
}