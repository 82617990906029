@use "sass:math";
@use "../../utilities";
@use "../../variables";

/* ================================================================
   Hero Slider and Static
================================================================ */

.hero-section {
    background-image: url(../images/hero-bg.jpg);
	background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: right top;
    padding: 150px 0;
    @include utilities.breakpoint(variables.$bp-mw) {
        padding: 170px 0 50px;
    }
    @include utilities.breakpoint(variables.$bp-xxl) {
        padding: 170px 0 80px;
    }

    &--dashed {
        background-image: url(../images/hero-home-bg.png);
    }
}
.hero {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    @include utilities.breakpoint(variables.$bp-mw) {
        flex-direction: row;
        align-items: center;
    }
}
.hero__box {
    order: 1;
    max-width: 480px;
    margin: auto;
    @include utilities.breakpoint(variables.$bp-mw) {
        padding-right: variables.$grid-gutter;
        max-width: 100%;
        order: 0;
        width: 50%;
    }
}
.hero__figure {
    position: relative;
    margin: 0 -#{variables.$grid-gutter} 40px;
    @include utilities.breakpoint(variables.$bp-mw) {
        margin: 0;
        flex: 1;
        max-width: 600px;
    }

    &::before {
        content: "";
        width: 80%;
        height: 80%;
        bottom: 20px;
        right: 40px;
        position: absolute;
        border-radius: 50%;
        background: variables.$color-gradient;
        mix-blend-mode: normal;
        opacity: 0.8;
        filter: blur(40px);
    }
}
.hero__title {
    font-size: utilities.pxtorem(48px);
    margin-bottom: variables.$grid-gutter;

    @include utilities.breakpoint(variables.$bp-mw) {
        font-size: 5.1vw;
    }
    @include utilities.breakpoint(variables.$container-size-em) {
        font-size: utilities.pxtorem(90px);
    }
}
.hero__desc {
    margin-bottom: variables.$grid-gutter;
    max-width: 550px;
	@include utilities.fluid-type(variables.$bp-s, variables.$container-size-em, 19px, 24px);
}
.hero__btn {
    margin-bottom: variables.$grid-gutter;
}
.hero__stores-title {
    margin-top: 20px;
    margin-bottom: 10px;
}
.hero__stores {
    display: flex;
}
.hero__store-link {
    transition: variables.$trans;
    transform: scale(1);
    text-decoration: none;
    
    &:hover, 
    &:active, 
    &:focus {        
		transform: scale(1.02);
    }
	&:not(:last-child) {
		margin-right: 14px;
	}

    img {
        height: 60px;
    }

}
.hero__img {
    display:block;
    background: no-repeat center center / cover;
    position: relative;
    z-index: 1;
    &::after { 
        content: ''; 
        display: block; 
        padding-top: math.percentage(math.div(1, 1)); 
    }

    &.regular {
        border-radius: 0;
        background: no-repeat center center / contain;
    }
    &.circle {
        border-radius: 50%;
    }
}