@use "../../utilities";
@use "../../variables";

//Sticky Header On Scroll Up Defaulted to mobile only

.sticky-header {
    transition: transform .4s;
}

@media (max-width: variables.$bp-m-max) {
    .sticky-header-mobile {
        position: sticky;
        top:0;
        left:0;
        right:0;
        z-index:999;
    }
    .sticky-header-down .sticky-header-mobile {
        transform: translate3d(0, -2000%, 0);
    }
}

//Sticky at all times on larger screens
@media (min-width: variables.$bp-mw) {
    .sticky-header-desktop {
        position: sticky;
        top:0;
        left:0;
        right:0;
        z-index:999;
    }
}
.scrolled {
    @include utilities.breakpoint(variables.$bp-mw) {       
        &.site-header {
            background-color: variables.$color-invert;
            box-shadow: 0 10px 10px 0 rgba(0,0,0, .03);
        }
    
        .header-top {
            @include utilities.breakpoint(variables.$bp-mw) {
                margin: 15px 0;
                border-bottom: none;
            }
        }
        .header-logo {
            max-width: 160px;
        }
        .main-navigation {
            > ul,
            .main-navigation-mega > ul {   
                > li {  
                    &.active {
                        &::before {
                          //  bottom: -10px;
                        }
                    }
                }
            }
        }
    }
}

html {
    scroll-padding-top: 150px;
}