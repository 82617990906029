@use "../../utilities";
@use "../../variables";
@use "../../globals/tables";


/* ================================================================
   Events - Majority of styling is done as part of the lists.scss
   ================================================================ */

.events {
	&.listing {

	}

	&.detail {

	}
}

/* =========================================================
 Calendar specific - Uses Full Calendar Plugin
============================================================ */

.calendar-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-top: 1px solid #d4d4d4;
}

.calendar-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 20px;
}

.calendar-nav-date {
    color: variables.$color-primary;
    margin: 0 50px 0 0;
    @include utilities.x-rem(font-size, 30px);
    font-weight: normal;
}

.calendar-nav-btns {
    display: flex;
    justify-content: flex-start;
	align-items: center;
	margin:0 -5px;
}
.calendar-nav-btns__button {
	background:variables.$color-primary;
	border:0;
	padding:5px;
	width:50px;
	height:50px;
	display:flex;
	align-items: center;
	justify-content: center;
	margin:0 5px;
	cursor:pointer;

	.calendar-nav-btns__button-svg {
		width:18px;
		height:18px;
		fill:variables.$color-invert;
	}

	&:hover,
	&:focus {
		background:variables.$color-secondary;
	}
}

.filter-view-btn {
	display:flex;
	align-items: center;
	justify-content: space-between;

	.filter-view-btn__svg {
		width:22px;
		height:22px;
		fill:variables.$color-invert;
		margin:0 20px 0 0;
	}
}

.calendar-main {
	@extend .table-responsive;
	
	.fc {
		min-width:calc(variables.$container-size - variables.$grid-gutter-em);
	}
}

.calendar {
    font-family: variables.$font-secondary;
    line-height: 1.3;

    table {
        margin: 0;
    }

    .event-meta {
        margin: 0 0 2px;
        @include utilities.x-rem(font-size, 12px);
        font-style: italic;
    }

    .event-title {
        margin: 0 0 3px;
        color: variables.$color-primary;
    }

    .event-extra {
        margin: 0 0 4px;
        font-weight: normal;
    }

    .event-location {
        display: flex;
        justify-content: flex-start;

        svg {
            position: relative;
            width: 15px;
            height: 15px;
            margin: 0 3px 0 0;
            fill: variables.$color-primary;
            top: 2px;
            flex: 0 0 auto;
        }
    }

    // Overrides
    .fc-day-grid-event {
        display: flex;
        flex-direction: column;
        margin: 0 10px 7px;
        @include utilities.x-rem(font-size, 14px);
        font-weight: bold;
        background: #f4f3f1;
        border: none;
        padding: 8px 5px 8px 15px;
        color: variables.$color-base;

        * {
            transition: variables.$trans;
        }

        &::after {
            content: 'View Event';
            display: block;
            color: variables.$color-primary;
            padding: 15px 5px 0 0;
            text-align: right;
            margin-top: auto;
        }

        &:hover,
        &:focus {
            background: variables.$color-primary;
            color: variables.$color-invert;

            .event-title {
                color: currentColor;
            }

            .event-location {
                svg {
                    fill: variables.$color-invert;
                }
            }

            &::after {
                color: currentColor;
            }
        }
    }

    .fc-day-grid-event .fc-content {
        white-space: normal;
        overflow: visible;
    }

    td.fc-day-top {
        padding: 10px 10px 17px 0;
        font-weight: bold;

        .fc-day-number {
            padding: 0;
        }

        &.fc-other-month {
            opacity: 0.55;
        }
    }

    .fc-day.fc-today {
        background: rgba(variables.$color-primary, .15) !important;
    }

    .fc {
        th {
            background: variables.$color-primary;
            color: variables.$color-invert;
            height: 55px;
            vertical-align: middle;
            text-transform: uppercase;
        }

        .fc-widget-content {
            border-left: none;

            &:last-child {
                border-right: none;
            }
        }
    }
}

@media (min-width: variables.$bp-mw) {
    .calendar-top {
        padding: 35px 0;
    }

    .calendar-nav {
        margin: 0;
    }

    .calendar-nav-date {
        margin: 0 50px 0 75px;
    }
}