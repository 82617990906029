@use "../../utilities";
@use "../../variables";

/* ================================================================
   Alerts
   ================================================================ */

.site-alert-container {
    background: variables.$color-gray;
    color: variables.$color-base;
    padding: 0 variables.$grid-gutter;
	position: relative;
    z-index: 1;
    @include utilities.breakpoint(variables.$container-size-large) {
        padding: 0;
    }
    &--primary {
        background-color: variables.$color-primary;
    }
    &--secondary {
        background-color: variables.$color-secondary;
    }
    &--purple {
        background-color: variables.$color-purple;
        color: variables.$color-invert;

        .site-alert__description {
            a {
                color: variables.$color-invert;
            }
        }
        .site-alert__close-button {
            &::before,
            &::after {
                background-color: variables.$color-invert;
            }
        }
    }
    &--orange {
        background-color: variables.$color-orange;
    }
}
.site-alert {
    display: flex;
    align-items: center;
    padding: 16px 20px 16px 0;
    
    @include utilities.breakpoint(variables.$bp-mw) {
        padding: 10px 0;        
    }

    + .site-alert {
        border-top: 1px solid variables.$color-dark-gray;
    }
}
.site-alert__image {
    max-width: 84px;
    padding: 0 20px 0 0;
}
.site-alert__description {
    width: 100%;
    @include utilities.x-rem(font-size, 16px);
    margin: 0;

    a {
        text-decoration: underline;
        color: variables.$color-base;
        font-weight: bold;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
    h3,
    h4,
    p {
        margin: 0;
    }
}
.site-alert__close-button {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    height: 35px;
    width: 35px;
    cursor: pointer;

    @include utilities.breakpoint(variables.$bp-mw) {
        position: relative; 
        top: inherit;
        right: inherit;       
    }

    &::before,
    &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 33px;
        width: 2px;
        background-color: variables.$color-base;
    }
    &::before {
        transform: rotate(45deg);
    }
    &::after {
        transform: rotate(-45deg);
    }
    &:hover,
    &:active,
    &:focus {
        opacity: 0.7;
    }
}
.cookie-consent + .site-alert-container {
    .site-alert {
        border-top: 1px solid variables.$color-dark-gray;
    }
}

/******************************** 
** Modal Alerts 
********************************/
.modal-alert-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 9991;
    &::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
    }
}
.modal-alert {
    position: absolute;
    z-index: 9992;
    width: 80%;
    height: 80%;
    background-color: #fff;
    display: flex;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.1);
	border-radius: 30px;	
	@include utilities.breakpoint(variables.$bp-mw) {
		width: 540px;
		height: 540px;	
		border-radius: 50%;	
	}
}
.modal-alert__close {
    position: absolute;
    width: 70px;
    height: 70px;
	background-color: #000;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	border: none;
	cursor: pointer;
    right: -7%;
    top: -4%;

	@include utilities.breakpoint(variables.$bp-mw) {
		right: 15%;
		top: 1%;
		
	}

	&::before,
	&::after {
		content: '';
		height: 33px;
		width: 2px;
		background-color: #fff;
	}
	&::before {
		transform: rotate(45deg);
	}
	&::after {
		transform: rotate(-45deg);
	}

	&:hover, 
	&:active, 
	&:focus {
		transform: scale(1.01);
	}
}
.modal-alert__description {
	font-size: utilities.pxtorem(24px);
	text-align: center;
	padding: 40px 60px;

	h2 {
		font-size: utilities.pxtorem(48px);
	}
}

/* confirm / speedbump */

.modaal-confirm {
    .speedBump_logo {
        max-width:300px;
        margin:0 auto 15px;
    }
    h1 {
        font-size: utilities.pxtorem(36px);
        margin: 20px;
    }
}

.modal-cta__content {
    margin: 20px 0 0;
}