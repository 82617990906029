@use "../variables";

.faq-outer-ul {
    padding-left: 0;
}
.faq-category-li {
    list-style-type: none;
}
.faq-question-li {
    list-style-type: disc;
    margin-bottom: 40px;
    @media (min-width: variables.$bp-sw) {
        margin-bottom: 80px;
    }
}
.faq-question-li::marker {
    font-size: 1.5em;
}
.faq-question {
    font-weight: 700;
    font-size: 1.625em;
}
.faq-inner-ul {
    padding-left: 20px;
    @media (min-width: variables.$bp-sw) {
        padding-left: 40px;
    }
}