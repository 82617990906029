@use "../utilities";
@use "../variables";

/* ================================================================
   Typography Styles
   ================================================================ */


h1, .h1, h2, .h2, h3, .h3 {
	margin:0 0 variables.$typography-margin-bottom;
	font-family:variables.$font-heading;
	font-weight: variables.$fontWeight-extrabold;
	line-height: 1.1;
}

h1, .h1 { 
	font-size: utilities.pxtorem(48px);
	@include utilities.breakpoint(variables.$bp-mw) {
		font-size: utilities.pxtorem(72px);		
	}
	sup {
		font-size: 50%;
		top: -1em;
	}
}
h2, .h2 {
	font-size: utilities.pxtorem(44px);
	@include utilities.breakpoint(variables.$bp-mw) {
		font-size: utilities.pxtorem(60px);		
	}
 }
h3, .h3 { 
	font-size: utilities.pxtorem(30px);
 }
h4, .h4 { font-family: variables.$font-primary }

a {
	color:variables.$color-base;
	text-decoration:underline;
	transition: variables.$trans;

	&:hover, &:focus { text-decoration:none; }
}

p { margin:0 0 variables.$typography-margin-bottom; }

// Very simple blockquote styling
blockquote {
	margin:1em 1.5em; padding-left:1.5em;
	border-left:5px solid hsl(0, 0%, 85%);
}

.smallest { font-size:0.8em; }
.smaller { font-size:0.9em; }
.larger { font-size:1.1em; }
.largest { font-size:1.2em; }

.bold { font-weight:bold; }
.italic { font-style:italic; }
.strike { text-decoration:line-through; }
.center { text-align: center;}

.nowrap { white-space:nowrap; }
.important { color:red; font-weight:bold; text-transform:uppercase; }

.title {
	font-weight: variables.$fontWeight-extrabold;
    margin: 0 -#{variables.$grid-gutter} variables.$grid-gutter;
    padding-left: variables.$grid-gutter;
	font-size: utilities.pxtorem(44px);
	@include utilities.fluid-type(variables.$bp-s, variables.$container-size-em, 44px, 72px);
	@include utilities.breakpoint(variables.$bp-mw) {
		font-size: utilities.pxtorem(60px);
	}
	@include utilities.breakpoint(variables.$bp-xxl) {
		font-size: utilities.pxtorem(72px);
	}
}
.desc {
	font-size: utilities.pxtorem(18px);
	margin-bottom: 40px;
}
h1.title {
	font-size: utilities.pxtorem(44px);
	@include utilities.breakpoint(variables.$bp-mw) {
		font-size: utilities.pxtorem(70px);
	}
	@include utilities.breakpoint(variables.$bp-xxl) {
		font-size: utilities.pxtorem(80px);
	}
}
.title--heart {	
	@include utilities.breakpoint(variables.$bp-s) {
		max-width: 10em;
	}
}
.title .curve {
    background-image: url(../images/titles/title-side-by-side.svg);
	background-position: right bottom 10px;
	background-size: 3em;
	padding-right: 3.2em;
    background-repeat: no-repeat;
	white-space: nowrap;
}
.title.curve {
	&::after { 
		content: '\00a0'; 
		display: inline; 		
		background-image: url(../images/titles/title-cards.svg);
		background-position: right bottom 15px;
		background-size: 2em;
		background-repeat: no-repeat;
		padding-right: 2em;
	}
	&--compare {
		&::after {		
			background-image: url(../images/titles/title-compare.svg);
		}
	}
	&--wallet {
		&::after {		
			background-image: url(../images/titles/title-mobile-wallet.svg);
		}
	}
	&--resources {
		&::after {
			background-image: url(../images/titles/title-resources.svg);
		}
	}
	&--cards {
		&::after {
			background-image: url(../images/titles/title-cards.svg);
		}
	}
	&--heart {
		&::after {
			background-image: url(../images/titles/title-side-by-side.svg);
		}
	}
}

.custom-list {
	@include utilities.resetList;
	font-size: utilities.pxtorem(20px);
	li {
		position: relative;
		padding-left: 25px;
		margin-bottom: 10px;
		&::before { 
			content: ''; 
			display: block; 
			width: 13px;
			height: 13px;
			border-radius: 50%;
			background-color: variables.$color-base;
			position: absolute;
			left: 0;
			top: 8px;
		}
	}
}