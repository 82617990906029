@use "sass:math";
@use "../../utilities";
@use "../../variables";

/* ================================================================
   Compare
================================================================ */

.compare {
	.title .curve {
		background-image: url(../images/titles/title-compare.svg);
	}
	.title.curve {
		&::after { 
			background-image: url(../images/titles/title-compare.svg);
		}
	}
    .title {
        max-width: 840px;
    }
}
.compare--CardsSecondary {
    .title {
        max-width: 1000px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        @include utilities.breakpoint(variables.$bp-mw) {
            margin-bottom: 50px;            
        }
		@include utilities.breakpoint(variables.$bp-xxl) {
            margin-bottom: 80px;       	
		}
    }
    .desc {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
    }
    .compare__desc strong {
        font-weight: 900;
    }
}
.compare--Carousel {
    .desc {
        max-width: 100%;
    }
}
.compare__list {
    @include utilities.resetList;
    @include utilities.breakpoint(variables.$bp-mw) {
        display: flex;
        margin: 0 -15px;
    }
}
.compare__item {
    border: 1px solid variables.$color-dark-gray;
    border-radius: 40px;
    padding: 50px 40px;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    background-color: #fff;
    box-shadow: 0 0 10px 10px rgba(0,0,0,.05);
    flex: 1;

    @include utilities.breakpoint(variables.$bp-mw) {
        margin: 0 15px;
        background-color: variables.$color-gray;
        box-shadow: none;

        &:hover, 
        &:active, 
        &:focus {
            background-color: #fff;
            box-shadow: 0 0 10px 10px rgba(0,0,0,.05);
        }
    }
}
.compare__item-top {
    margin-bottom: variables.$grid-gutter;
    @include utilities.breakpoint(variables.$bp-mw) {
        display: flex;
        align-items: center;
    }
}
.compare__figure {
    margin-bottom: 30px;
    @include utilities.breakpoint(variables.$bp-mw) {
        width: 30%;
        max-width: 100%;
        margin-right: variables.$grid-gutter;
        margin-bottom: 0;
    }
}
.compare__img {
    border-radius: 10px;
}
.compare__heading {
    margin-bottom: 0;
    font-size: utilities.pxtorem(34px);
    line-height: 1;
    @include utilities.breakpoint(variables.$bp-xxl) {
        font-size: utilities.pxtorem(44px);        
    }
}
.compare__desc {
    margin-bottom: 30px;
    font-size: utilities.pxtorem(20px);

    @include utilities.breakpoint(variables.$bp-mw) {
        font-size: utilities.pxtorem(24px);        
    }

    ul {
        margin-top: 30px;
        list-style: disc;
        font-weight: variables.$fontWeight-extrabold;
    }
    li {
        margin-bottom: 10px;
    }

    table {
        td  {
            padding-bottom: 20px;
            padding-right: 10px;
            vertical-align: top;
        }
    }
}
.compare__item-link {
    font-weight: variables.$fontWeight-extrabold;
    text-decoration: underline;
    color: variables.$color-purple;
    margin: 30px auto 30px 0;
    font-size: utilities.pxtorem(20px);

    @include utilities.breakpoint(variables.$bp-mw) {
        font-size: utilities.pxtorem(26px);        
    }
    &:hover, 
    &:active, 
    &:focus {
        text-decoration: none;
    }
}
.compare__item-btn {
    margin-top: 40px;
    @include utilities.breakpoint(variables.$bp-mw) {
        margin-top: auto;
    }
}
.compare__btn {
    margin: 70px auto 0;
    display: block;
    width: fit-content;
}

/******************************** 
** Compare Two Cards 
********************************/

.compare__list--two-cards {
    .compare__item {
        background-color: #fff;
        @include utilities.breakpoint(variables.$bp-mw) {
            padding: 80px;
        }
    }
    .compare__heading {
        text-align: center;
        max-width: 520px;
        margin-left: auto;
        margin-right: auto;
    }
    .compare__item-top {
        border-bottom: 1px solid #A9A9A9;
        padding: 40px 0;
    }
    .compare__text {
        font-size: utilities.pxtorem(22px);
        flex: 1;
    }
    .compare__figure {

    }
    .compare__img {
        display:block;
        background: no-repeat center center / contain;
        
        &::after { 
            content: ''; 
            display: block; 
            padding-top: math.percentage(math.div(1, 1)); 
        }
    }
    .compare__item-links {
        @include utilities.breakpoint(variables.$bp-mw) {
            margin-top: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .compare__item-link {
        display: block;
        margin: 0;
        text-align: center;

    }
    .compare__item-btn {
        display: block;
        margin: 0;
        margin-right: 20px;
        margin-bottom: 20px;
        @include utilities.breakpoint(variables.$bp-mw) {
            margin-bottom: 0;
        }
    }
}