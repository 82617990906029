@use "../../utilities";
@use "../../variables";

/* ================================================================
   Tabs
================================================================ */
.tabs {
    display: inline-flex;
    gap: 8px;
    border-bottom: 1px solid variables.$color-dark-gray;
}
.tab {
    display: none;
    @include utilities.breakpoint(variables.$bp-mw) {
        @include utilities.resetBtn;
        display: block;
        border-bottom: 4px solid transparent;
        padding: 8px 16px;
        font-size: utilities.pxtorem(18px);

        &.active {
            border-color: variables.$color-secondary;
            font-weight: bold;
        }
    }
}
.tab--mobile {
    @include utilities.resetBtn;
    display: block;
    width: 100%;
    text-align: left;
    @include utilities.breakpoint(variables.$bp-mw) {
        display: none;
    }
    
    &[data-expander-toggle] {
        position: relative;
        padding-right: 40px;
        @include utilities.breakpoint(variables.$bp-mw) {
            padding-right: 60px;            
        }

        // Arrows
        &:after {
            border-style: solid;
            border-width: 2px 2px 0 0;
            content: "";
            display: inline-block;
            height: 8px;
            width: 8px;
            right: 20px;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            transform: rotate(135deg);
            vertical-align: top;
            border-color: variables.$color-base !important;
            @include utilities.breakpoint(variables.$bp-mw) {
                right: 40px;      
            }
        }

        &.active {
            &:after {
                transform: rotate(-45deg);
            }
        }
    }
}
.tab-target {
    padding: 20px 0;
}