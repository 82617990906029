@use "../utilities";
@use "../variables";

/* ================================================================
   Button Styles
   ================================================================ */

.button,
.btn {
	display: inline-block;
	padding: 20px 45px;
	border: 0;
	border-radius: 30px;
	font-family: variables.$font-primary;
	color: variables.$color-invert;
	text-decoration: none;
	text-align: center;
	background: variables.$color-purple;
	cursor: pointer;
	transition: variables.$trans;
	line-height: 1;
	font-size: utilities.pxtorem(18px);
	font-weight: variables.$fontWeight-bold;
	transform: scale(1);
	height: auto;

	&:hover,
	&:focus {
		opacity: .9;
		transform: scale(1.02);
	}

	+.btn {
		margin: 5px;
	}

	&--disabled {
		opacity: 0.5;
		cursor: not-allowed;

		&:hover,
		&:focus {
			opacity: 0.5;
			transform: scale(1);
		}
	}

	&--primary {
		background-color: variables.$color-primary;
		color: variables.$color-base;
	}

	&--secondary {
		background-color: variables.$color-secondary;
		color: variables.$color-base;
	}

	&--purple {
		background-color: variables.$color-purple;
		color: variables.$color-invert;
	}

	&--cyan {
		background-color: variables.$color-cyan;
		color: variables.$color-invert;
	}

	&--white {
		background-color: #fff;
		color: variables.$color-purple;
		border: 1px solid #C7C7C7;
	}

	&--link {
		background-color: transparent;
		color: variables.$color-base;
		width: fit-content;
		padding-left: 0;

		&:hover,
		&:active,
		&:focus {
			background-color: transparent;
			transform: scale(1);
		}
	}

	&--underline {
		text-decoration: underline;
		color: variables.$color-base;

		&:hover,
		&:active,
		&:focus {
			text-decoration: none;
		}
	}

	&--plain {
		background-color: transparent;
		border: 1px solid currentColor;
		color: variables.$color-base;
	}

	&--arrow {
		position: relative;
		padding-right: 2em;
		transition: variables.$trans;

		&::after {
			border-style: solid;
			border-width: 0.15em 0.15em 0 0;
			content: '';
			display: inline-block;
			height: 0.5em;
			width: 0.5em;
			right: 1em;
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
			transform: rotate(45deg);
			vertical-align: top;
		}

		&:hover,
		&:active,
		&:focus {
			&::after {
				right: 0.8em;
			}
		}
	}

	&--icon,
	&--icon-arrow {
		position: relative;
		display: flex !important;
		align-items: center;

		svg {
			width: 20px;
			height: 20px;
			margin-right: 5px;
			transition: variables.$trans;
		}
	}

	&--small {
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

.btn--link.btn--icon-arrow {
	margin: auto;
	position: static;
	height: unset;
	transform: none;

	svg {
		fill: currentColor;
		transform: rotate(90deg);
	}

	&:hover,
	&:active,
	&:focus {
		svg {
			transform: translateX(5px) rotate(90deg);
		}
	}
}

.button-2 {
	display: inline-block;
	padding: 0.47em 1.2em;
	border: 0;
	font-family: variables.$font-primary;
	color: variables.$color-invert;
	text-decoration: none;
	text-align: center;
	background: variables.$color-secondary;
	cursor: pointer;
	transition: variables.$trans;
	line-height: 1.4;

	&:hover,
	&:focus {
		text-decoration: none;
		background: variables.$color-invert;
		color: variables.$color-primary;
	}
}

.back {
	@extend .button-small !optional;
	text-transform: capitalize;
}

.more {
	@extend .button-small !optional;
	text-transform: capitalize;
}

%carousel-btn {
	@include utilities.resetBtn;
	position: absolute;
	width: 60px;
	height: 60px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 5;
	font-size: 0;
	color: variables.$color-primary;
	transition: variables.$trans;
	background: none;
	border: none;
	cursor: pointer;
	padding: 0;

	svg {
		display: inline-block;
		width: 100%;
		height: 100%;
		fill: currentColor;
		transition: variables.$trans;
	}

	&:hover,
	&:focus {
		color: variables.$color-secondary;
	}

	@media (min-width: variables.$bp-mw) {
		width: 60px;
		height: 60px;
	}
}

.play {
	position: absolute;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 140px;
	height: 140px;
	border-radius: 50%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	background-color: variables.$color-purple;

	&::before {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-top: 30px solid transparent;
		border-bottom: 30px solid transparent;
		border-left: 40px solid variables.$color-invert;
		position: relative;
		left: 5px;
	}
}

/******************************** 
** CKE Buttons 
********************************/
.btn-primary a {
	display: inline-block;
	padding: 20px 45px;
	border: 0;
	border-radius: 30px;
	font-family: "Mulish", sans-serif;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	transition: all 0.1s ease-in-out;
	line-height: 1;
	font-size: 1.125rem;
	font-weight: 700;
	transform: scale(1);
	height: auto;
	background-color: variables.$color-primary;
	color: #000;

	&:hover,
	&:focus {
		opacity: .9;
		transform: scale(1.02);
	}
}

.btn-secondary a {
	display: inline-block;
	padding: 20px 45px;
	border: 0;
	border-radius: 30px;
	font-family: "Mulish", sans-serif;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	transition: all 0.1s ease-in-out;
	line-height: 1;
	font-size: 1.125rem;
	font-weight: 700;
	transform: scale(1);
	height: auto;
	background-color: variables.$color-secondary;
	color: #000;

	&:hover,
	&:focus {
		opacity: .9;
		transform: scale(1.02);
	}
}

.btn-purple a {
	display: inline-block;
	padding: 20px 45px;
	border: 0;
	border-radius: 30px;
	font-family: "Mulish", sans-serif;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	transition: all 0.1s ease-in-out;
	line-height: 1;
	font-size: 1.125rem;
	font-weight: 700;
	transform: scale(1);
	height: auto;
	background-color: variables.$color-purple;
	color: #fff;

	&:hover,
	&:focus {
		opacity: .9;
		transform: scale(1.02);
	}
}