@use "sass:math";
@use "../../utilities";
@use "../../variables";

/* ================================================================
   Locations
================================================================ */

/******************************** 
** Location Scheduler
********************************/
.location-dropdown-list {
    margin-top: 2.5em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.location-dropdown-list #locationSelect {
    max-width: 100%;
    cursor: pointer;
}

.location-dropdown-list .btn {
    max-width: max-content;
}

.location-dropdown-error {
    color: #7A0000;
    margin: 0;
}



/******************************** 
** Locations Listing 
********************************/
.side-by-side--location-search {
	.side-by-side__box {
		@include utilities.breakpoint(variables.$bp-l) {
			width: 40%;
		}
	}
}
.locations-listing {
    background-color: variables.$color-gray;
    padding: 40px 0;
}
.locations-listing-inner {
    @include utilities.breakpoint(variables.$bp-mw) {
        padding: 0 40px;
    }
    @include utilities.breakpoint(variables.$bp-l) {
        padding: 0 140px;
    }
}
.locations-listing__list {
    @include utilities.resetList;
    margin: 20px 0 40px;
    @include utilities.breakpoint(variables.$bp-mw) {
        margin: 40px 0 80px;
    }
}
.locations-listing__item {
    padding: 40px 0;
    border-bottom: 1px solid variables.$color-dark-gray;
    position: relative;
    z-index: 1;

    @include utilities.breakpoint(variables.$bp-mw) {
        padding: 65px 0;
    }

    &:first-child {
        border-top: 1px solid variables.$color-dark-gray;
    }
    &:hover, 
    &:active, 
    &:focus {
        border-color: transparent;
        &::before {
            content: "";
            display: block;
            height: 100%;
            position: absolute;
            z-index: -1;
            top: -1px;
            background-color: #fff;
            border-radius: 30px;
            box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.05);
            width: calc(100% + 30px);
            left: -15px;
        }
        @include utilities.breakpoint(variables.$bp-mw) {
            &::before {
                width: calc(100% + 80px);
                left: -40px;
            }
        }
        @include utilities.breakpoint(variables.$bp-l) {
            &::before {
                width: calc(100% + 280px);
                left: -140px;
            }
        }

    }
}
.locations-listing__item-top-item {
    position: relative;
    display: inline-block;
    padding-right: 30px;
    font-weight: variables.$fontWeight-extrabold;
    text-transform: uppercase;

    &::after {
        content: "";
        display: block;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
        height: 12px;
        background: variables.$color-dark-gray;
    }
    &:last-child {
        padding: 0;

        &::after {
            content: none;
        }
    }

    svg {
        width: 17px;
        height: 17px;
        display: inline-block;
        position: relative;
        top: 3px;
        margin-right: 5px;
    }
}
.locations-listing__item-dist {
    &::before {
        content: "";
        display: inline-block;
        width: 13px;
        height: 13px;
        background-color: variables.$color-secondary;
        border-radius: 50%;
        margin-right: 5px;
    }
}
.locations-listing__item-heading {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 20px 0;
}
.locations-listing__item-title {
    margin-bottom: 0;
    margin-right: 10px;
}
.locations-listing__item-title-helper {
    @include utilities.fluid-type(variables.$bp-s, variables.$container-size-em, 34px, 48px);
}

.locations-listing__item-grid {
    @include utilities.breakpoint(variables.$bp-mw) {
        display: flex;
    }
}
.locations-listing__item-col {
    flex: 1;
    font-size: utilities.pxtorem(20px);
    margin-bottom: 20px;

    @include utilities.breakpoint(variables.$bp-mw) {
        margin-bottom: 0;
        border-left: 1px solid variables.$color-dark-gray;
        padding: 0 20px;
    }

    .btn--arrow {
        padding-left: 0;
    }
}
.locations-listing__item-col:first-child {
    border-left: none;
    padding: 0;
    .locations-listing__item-col-inner {
        margin-left: 0;
    }
}
.locations-listing__item-col-inner {
    @include utilities.breakpoint(variables.$bp-mw) {
        max-width: 225px;
        margin: auto;
    }
}
.locations-listing__item-subtitle {
    font-size: utilities.pxtorem(26px);
    margin-bottom: 1rem;
}

/******************************** 
** Location Search 
********************************/
.location-search {
}
.side-by-side--location-search {
    .side-by-side__figure {
        transform: scale(1);
        margin: 0 variables.$grid-gutter;
    }
    .location-search__map {
        position: relative;
        padding-top: math.percentage(3*0.25);
        z-index: 1;
        border-radius: 30px;
        @include utilities.breakpoint(variables.$bp-mw) {
            padding-top: math.percentage(math.div(770, 690));            
        }
    }
}
.location-search__filters {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid variables.$color-dark-gray;
	padding-bottom: 30px;
	@include utilities.breakpoint(variables.$bp-mw) {
		max-width: 650px;
	}
}
.location-search__filter {
    margin-bottom: 30px;
    @include utilities.breakpoint(variables.$bp-mw) {
        width: 50%;
    }
}

.location-search__filter-label {
    position: relative;
    cursor: pointer;
    padding: 10px 0;
}
.location-search__filter-checkbox {
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;

    &:hover,
    &:focus {
        ~ .location-search__filter-text {
            opacity: 0.8;
            &:after {
            }
        }
    }
    &:focus {   
        ~ .location-search__filter-text {
            text-decoration: underline;
            &:after {
                border-radius: 50%;
            }
        }
    }
    &:checked {
        ~ .location-search__filter-text {
            &:after {
                border-radius: 50%;
                background: variables.$color-purple;
                border-color: variables.$color-purple;
            }
            &:before {
                display: block;
            }
        }
    }
}

.location-search__filter-text {
    padding: 14px 14px 14px 50px;
    font-size: utilities.pxtorem(20px);
    font-weight: variables.$fontWeight-bold;

    &:after {
        content: "";
        position: absolute;
        background: #eeeeee;
        border: 1px dashed variables.$color-base;
        border-radius: 5px;
        width: 40px;
        height: 40px;
        left: 0;
        margin: auto;
        top: 6px;
        transition: variables.$trans;
    }
    &:before {
        content: "";
        position: absolute;
        display: none;
        left: 15px;
        width: 10px;
        height: 20px;
        border: solid variables.$color-base;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        z-index: 2;
        margin: auto;
        top: 14px;
        transition: variables.$trans;
    }
}
.location-search__filter:nth-child(2n) {
    .location-search__filter-checkbox {
        &:checked {
            ~ .location-search__filter-text {
                &:after {
                    background: variables.$color-secondary;
                    border-color: variables.$color-secondary;
                }
            }
        }
    }
}
.location-search__filter:nth-child(3n) {
    .location-search__filter-checkbox {
        &:checked {
            ~ .location-search__filter-text {
                &:after {
                    background: variables.$color-primary;
                    border-color: variables.$color-primary;
                }
            }
        }
    }
}
.location-search__filter:nth-child(4n) {
    .location-search__filter-checkbox {
        &:checked {
            ~ .location-search__filter-text {
                &:after {
                    background: variables.$color-orange;
                    border-color: variables.$color-orange;
                }
            }
        }
    }
}

.location-search__form {
    margin: 30px 0;
    @include utilities.breakpoint(variables.$bp-mw) {
        max-width: 460px;
    }
}
.location-search__form-legend {
    font-size: utilities.pxtorem(24px);
    margin-bottom: 30px;
}
.location-search__form-use-location {
    padding-left: 0;
}
.location-search__form-btn {
    width: 100%;
    margin-top: 20px;
}

/******************************** 
** Location Details 
********************************/

.locations-details__main {
	@include utilities.breakpoint(variables.$bp-mw) {
		display: flex;
	}
}
.locations-details__main-content {
	@include utilities.breakpoint(variables.$bp-mw) {
		width: 45%;
		padding-right: 40px;
	}
	@include utilities.breakpoint(variables.$bp-l) {
		padding-right: 110px;
	}
}

/******************************** 
** Main Content 
********************************/
.locations-details__top {
	@extend .locations-listing__item-top-item;
}
.locations-details__dist {
	@extend .locations-listing__item-dist;
}
.locations-details__heading {
	@extend .locations-listing__item-heading;
}
.locations-details__title {
	@extend .locations-listing__item-title;
}
.locations-details__title-helper {
	@extend .locations-listing__item-title-helper;
	@include utilities.fluid-type(variables.$bp-s, variables.$container-size-em, 34px, 54px);
}
.locations-details__info-box {

	&:not(:last-child) {
		border-bottom: 1px solid variables.$color-dark-gray;
		padding-bottom: 55px;
		margin-bottom: 55px;
	}

	.btn--link {
		padding-left: 0;
	}
}
.locations-details__info-btn {
	margin: 20px 0;
}

.locations-details__info-grid  {
	display: flex;
	.locations-details__info-col {
		margin-right: 50px;
	}
}
.locations-details__info-subtitle {
	font-size: utilities.pxtorem(26px);
	margin-bottom: 10px;
}

/******************************** 
** Main Graphics 
********************************/
.locations-details__main-graphic {
	margin-top: 60px;
	@include utilities.breakpoint(variables.$bp-mw) {
		margin-top: 0;
		flex: 1;
	}
}
.locations-details__main-graphic-figure {
	position: relative;
	margin-bottom: 40px;

	@include utilities.breakpoint(variables.$bp-mw) {
		margin-bottom: 80px;		
	}
	&::before {
		content: "";
		width: 100%;
		height: 100%;
		bottom: -20px;
		right: -20px;
		position: absolute;
		border-radius: 50%;
		background: variables.$color-gradient;
		mix-blend-mode: normal;
		opacity: 0.6;
		filter: blur(60px);
	}
}
.locations-details__main-graphic-img{
	position: relative;
	z-index: 1;
	display:block;
	background: no-repeat center center / cover;
	border-radius: 30px;
    width: 100%;
    height: 300px;
    overflow: hidden;
    @include utilities.breakpoint(variables.$bp-mw) {
        height: 780px;        
    }
	&::after { 
		content: ''; 
		display: block; 
		padding-top: math.percentage(math.div(1, 1)); 
	}
}
.locations-details__main-graphic-img--map {
	&::after { 
		padding-top: math.percentage(math.div(780, 900)); 
	}
}
.locations-details__main-graphic-img--office {
	&::after { 
		padding-top: math.percentage(math.div(580, 900)); 
	}
}

/******************************** 
** Map Pin Details 
********************************/
.location-listings__map-detail {
    padding: 15px;
    font-size: utilities.pxtorem(16px);
    font-family: variables.$font-primary;
    line-height: 1.5;
    width: 260px;
}
.location-listings__map-detail-header {
    font-size: utilities.pxtorem(24px);
    font-family: variables.$font-heading;
    font-weight: variables.$fontWeight-bold;
    margin-bottom: 20px;
    display: block;
    text-decoration: none;

    &:hover, 
    &:active, 
    &:focus {
        text-decoration: underline;
    }
}
.location-listings__map-detail-btn {
    margin: auto;
}
.location-listings__map-detail-bottom {
    margin: 20px 0 0;
    padding: 20px 0 0;
    border-top: 1px solid variables.$color-gray;
    text-align: center;
}
.location-search__map .gm-ui-hover-effect {
    top: -5px !important;
    right: 8px !important;
    opacity: 1 !important;

    span {
        width: 30px !important;
        height: 30px !important;
    }
}

.hours-closure {
    padding: 40px 0;
}