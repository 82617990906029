@use "../../utilities";
@use "../../variables";

/* ================================================================
   Classes
================================================================ */

.class-details {
}
.class-details__left {
    margin-bottom: 30px;
}
.class-details__heading {
    font-size: utilities.pxtorem(22px);
    margin-bottom: 0;
}
.class-details__desc {
    margin-bottom: 20px;
}
.class-details__grid {
    @include utilities.breakpoint(variables.$bp-m) {        
        display: flex;
        flex-wrap: wrap;
    }
}
.class-details__col {
    @include utilities.breakpoint(variables.$bp-m) {
        width: 50%;
    }
}
.class-details__inner {
    @include utilities.breakpoint(variables.$bp-m) {
        padding-right: 20px;
    }
}