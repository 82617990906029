@use "sass:math";
@use "../../utilities";
@use "../../variables";

$side-by-side-margin: 30px;
$side-by-side-margin-dsk: 60px;

.side-by-side-section {
    margin: 40px auto;
    @include utilities.breakpoint(variables.$bp-mw) {
        margin: 80px auto;
    }

    .title {
        margin-bottom: 45px;
        @include utilities.fluid-type(variables.$bp-s, variables.$container-size-em, 44px, 90px);
        
        @include utilities.breakpoint(variables.$bp-s) {
            max-width: 10em;
        }
    }
    .title .curve {
        background-image: url(../images/titles/title-side-by-side.svg);
        background-position: right bottom 10px;
        background-size: 3em;
        padding-right: 3.2em;
    }
}

.side-by-side {
    margin: 0 -#{variables.$grid-gutter} $side-by-side-margin;
    padding: 0;
    overflow: hidden;

    & + & {
        margin-top: -$side-by-side-margin - 1px;
    }

    .btn {
        display: block;
    }
    @include utilities.breakpoint(variables.$bp-mw) {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        text-align: left;
        margin: 0 0 40px;
        padding: 0;
        overflow: inherit;

        & + & {
            margin-top: -$side-by-side-margin-dsk - 1px;
        }

        .btn {
            display: inline-block;
        }
    }
    @include utilities.breakpoint(variables.$bp-xxl) {
        margin: 0 0 $side-by-side-margin-dsk;        
    }
}

.side-by-side__figure {
    position: relative;
    z-index: 1;
    margin-bottom: 60px;
    margin-top: 50px;
    z-index:10;
    padding: 0 variables.$grid-gutter;
    
    @include utilities.breakpoint(variables.$bp-mw) {
        flex: 0 0 auto;
        width: 35%;
        margin: 0 80px 0 0;
        padding: 0;
    }

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        bottom: -30px;
        right: -50px;
        position: absolute;
        border-radius: 50%;
        background: variables.$color-gradient;
        mix-blend-mode: normal;
        opacity: 0.8;
        filter: blur(40px);
    }
}
.side-by-side__img {
    display: block;
    background: no-repeat center center / cover;
    border-radius: 50%;
    z-index: 1;
    position: relative;
    &::after {
        content: "";
        display: block;
        padding-top: math.percentage(math.div(1, 1));
    }

    &--large {
        @include utilities.breakpoint(variables.$bp-mw) {
            transform: translateX(-80px) scale(1.2);
        }
    }

    &.regular {
        border-radius: 0;
        background: no-repeat center center / contain;
    }
}

.side-by-side__content {
    font-size: utilities.pxtorem(24px);
    > *:last-child {
        margin-bottom: 0;
    }
    @include utilities.breakpoint(variables.$bp-mw) {
        width: 100%;
    }
    .desc {
        .button {
            margin: 10px 10px 10px 0;
        }
    }
}

.side-by-side__links {
    text-align: center;

    .btn {
        margin-bottom: 25px;
    }

    @include utilities.breakpoint(variables.$bp-mw) {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .btn {
            margin: 0 10px 0 0;
        }
    }
}

.side-by-side--flipped {
    @include utilities.breakpoint(variables.$bp-mw) {
        .side-by-side__figure {
            order: 2;
            margin: 0 0 0 80px;
        }
    }
}

.side-by-side__box {
    padding: 40px variables.$grid-gutter;
    position: relative;
    z-index: 1;
    @include utilities.breakpoint(variables.$bp-mw) {
        padding: 40px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
        width: 45%;
    }
}

.side-by-side__figure .video-modal,
.side-by-side__figure .video-modal-inline,
.side-by-side__figure .video-iframe-modal {
    .play {
        transition: variables.$trans;
        opacity: 0.8;
    }
    &:hover,
    &:active,
    &:focus {
        .play {
            transform: scale(1.05);
            opacity: 1;
        }
    }
}
.video-embed {
    display: none;
}

.two-col-list {
    list-style: none;    
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.two-col-list li {
    flex: 1 45%;
    margin-bottom: 10px;
}
.checklist {
    @include utilities.resetList;
    li {
        margin-bottom: 30px;
        display: flex;
        
        &::before {
            content:'';
            display: block;
            width:25px;
            height:25px;
            background-image: url('../images/checklist-icon.svg');
            background-size: contain;
            background-repeat: no-repeat;
            display: inline-block;
            position: relative;
            top: 5px;
            margin-right: 20px;
            flex-shrink: 0;
        }
    }
}

.side-by-side--location-search {
    .side-by-side__figure {
        @include utilities.breakpoint(variables.$bp-mw) {
            width: 45%;
        }
    }
}

.side-by-side--medium {
    .side-by-side__figure {
        @include utilities.breakpoint(variables.$bp-mw) {
            max-width: 400px;
        }
    }
    .side-by-side__box {
        flex: 1;
    }
}
.side-by-side--small {
    .side-by-side__figure {
        @include utilities.breakpoint(variables.$bp-mw) {
            max-width: 250px;
        }
    }
    .side-by-side__box {
        flex: 1;
    }
}