@use "../utilities";
@use "../variables";
@use "./buttons";

/* ================================================================
   Variables
   ================================================================ */

$input-height: 70px !default;
$input-background: #ffffff !default;
$input-border-color: variables.$color-base !default;
$input-border-color-selected: variables.$color-purple !default;
$input-border-width: 1px !default;
$input-padding: 5px 10px;
$form-spacing: 20px;
$note-size: 14px;
$error-color: #7A0000;
$info-success: green;
$info-color: #225894;

/* ================================================================
   Required
   ================================================================ */

.required-indicator {
	position:relative;
	padding-left:10px;

	&:before {
		display:block; content:'*';
		position:absolute; left:0; top:0;
		color:$error-color;
	}
}

/* ================================================================
   Controls/Inputs
   ================================================================ */

label {
	vertical-align: middle;
}

input {
	padding: $input-padding;
	@include utilities.x-rem(font-size, 16px);
	border: $input-border-width solid $input-border-color;
	border-radius:0;
	vertical-align: middle;
	height: $input-height;
	max-width: 100%;
	transition: all 0.2s ease-in-out;

	&:hover,
	&:focus {
		border-color: $input-border-color-selected;
	}
}

input[type="checkbox"],
input[type="radio"] {
	margin-right: 5px;
	border-radius: 0;
	vertical-align: middle;
	height: auto;
}

// File inputs are very tricky cross browser so recreating height using padding
input[type="file"] {
	height: auto;
	padding-top: 0.345rem;
	padding-bottom: 0.345rem;
}

select {
	border: $input-border-width solid $input-border-color;
	vertical-align: middle;
	height: $input-height;
	padding: $input-padding;
	max-width: 100%;
	font-family: variables.$font-primary;
	font-size: utilities.pxtorem(18px);
	background-color: #fff;

	&[multiple] { height: auto; }

	&:hover,
	&:focus {
		border-color: $input-border-color-selected;
	}
}

textarea {
	padding: $input-padding;
	min-height: 100px;
	border: $input-border-width solid $input-border-color;
	border-radius:0;
	background: $input-background;
	max-width: 100%;
	transition: all 0.2s ease-in-out;

	&:hover,
	&:focus {
		border-color: $input-border-color-selected;
	}
}

button {
	vertical-align: middle;
}

/* ================================================================
   Remember me switch
   ================================================================ */

.form-group label.toggle-switch__label {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	position: relative;
	cursor: pointer;
	gap: 16px;
	pointer-events: initial;
	left: initial;
	top: initial;
	transform: initial;
	width: fit-content;
}

.toggle-switch__input {
	position: absolute;
	opacity: 0;
	width: 100%;
	height: 100%;
}

.toggle-switch__display {
	--offset: 0.25em;
	--diameter: 1.8em;
	display: inline-flex;
	align-items: center;
	justify-content: space-around;
	width: 50px;
	height: 24px; 
	position: relative;
	border-radius: 30px;
	background-color: variables.$color-jet-black;
	transition: all 0.25s ease;
}

.toggle-switch__display::before {
	content: "";
	z-index: 2;
	position: absolute;
	top: 50%;
	left: 1px;
	width: 22px;
	height: 22px;
	border: 1px solid variables.$color-jet-black;
	border-radius: 50%;
	background-color: variables.$body-bg;
	transform: translate(0, -50%);
	will-change: transform;
	transition: inherit;
} 
.toggle-switch__label:focus .toggle-switch__display,
.toggle-switch__input:focus + .toggle-switch__display {
	outline: 1px dotted variables.$color-primary;
	outline: 1px auto -webkit-focus-ring-color;
	outline-offset: 2px;
}
.toggle-switch__label:focus,
.toggle-switch__label:focus:not(:focus-visible)
	.toggle-switch__display,
.toggle-switch__input:focus:not(:focus-visible)
	+ .toggle-switch__display {
	outline: 0;
}
.toggle-switch__input:checked + .toggle-switch__display {
	background: variables.$color-toggle-green;
}
.toggle-switch__input:checked + .toggle-switch__display::before {
	transform: translate(110%, -50%);
}

.toggle-switch__icon {  
	fill: variables.$body-bg;
	width: 14px;
	height: 11px;  
}

.toggle-switch__icon--cross {  
}

.toggle-switch__icon--checkmark { 
}
/* ================================================================
   Kentico CMS
   ================================================================ */

.InfoLabel {
	color: $info-color;
}

.ErrorLabel {
	color: $error-color;
}

.SuccessLabel {
	color: $info-success;
}

.EditingFormErrorLabel {
	display: block;
	color: $error-color;
	margin: $form-spacing 0 0 0;
}

.FormPanel {
	display: block;
	max-width: variables.$bp-sw;

	table.EditingFormTable {
		width: 100%;

		thead,
		tbody,
		tr,
		td { display: block; }

		tr {
			padding: $form-spacing 0;
			border-top: 1px solid #b8b8b8;

			&:first-child { border-top: none; }
		}

		label,
		.EditingFormLabel {
			display: block;
			margin: 0 0 $form-spacing;
			font-weight: bold;
		}

		input,
		select,
		textarea {
			width: 100%;

			&[type="checkbox"],
			&[type="radio"] {
				width: auto;
			}
		}

		.ExplanationText {
			margin: $form-spacing 0 0 0;
			@include utilities.x-rem(font-size, $note-size);
		}

		.checkbox { display: inline-block; }

		// Single checkbox styling
		.single-checkbox-field {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: center;

			label { margin: 0 $form-spacing 0 0; }

			.EditingFormValueCell {
				font-size: 0;
			}

			.ExplanationText {
				@include utilities.x-rem(font-size, $note-size);
			}
		}

		// Multiple checkbox styling
		.multi-checkbox-field {
			label {
				display: inline-block;
				margin: 0;
				font-weight: normal;
			}

			input {
				display: inline-block;
				width: auto;
			}
		}

		// Radio list styling
		.radio-list-field {
			label {
				display: inline-block;
				margin: 0;
				font-weight: normal;
			}

			input {
				display: inline-block;
				width: auto;
			}
		}

		// Date picker styling
		.date-picker-field {
			input { margin: 0 0 $form-spacing 0; }

			button {
				width: 100%;
				margin: 0 0 $form-spacing 0;
			}

			.calendar-action { display: none; }

			.form-control-text {
				@extend .btn;
				width: 100%;
			}
		}

		// Captcha
		.captcha-field {
			.CaptchaTable {
				width: 100%;
				margin: 0;
				font-size: 0;
				border-collapse: collapse;

				tr {
					border: none;
					padding: 0;
				}

				input {
					@include utilities.x-rem(font-size, 16px);
					margin: $form-spacing 0;
				}
			}
		}

		// Phone
		.phone-field {
			.editing-form-control-nested-control {
				 display: flex;
				 justify-content: space-between;
				 align-items: center;
				 max-width: variables.$bp-s;

				 span {
					 flex: 0 0 auto;
					 margin: 0 5px;
				 }

				 input {
					 min-width: 0;
					 flex: 1 1 100%;
				 }
			}
		}

		// Media selection styling
		.MediaSelectorHeader {
			input:not(:last-child) { margin-bottom: $form-spacing; }
		}

		// Calendar
		.icon-calendar + .sr-only {
			display: inline-block;
			overflow: visible;
			position: static;
			clip: auto;
			width: auto;
			height: auto;
		}
	}
}

// jQuery date picker popup
#ui-datepicker-div {
	z-index: 8999 !important;

	.datetime-ui-datepicker-prev,
	.datetime-ui-datepicker-next {
		display: none;
	}

	@at-root html[class^="js-"] & .action-buttons {
		display: none;
	}
}

@media (min-width: variables.$bp-mw) {
	.FormPanel {
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}
}

// form builder

div[id*='form-'],
.inline-form {

	p {
		@media (min-width: variables.$bp-mw) {
			@include utilities.x-rem(font-size, 20px);
		}
	}

	&.inline-form--slim {
		max-width:660px;
		margin:0 0 20px;
	}
	
	form {
	  width: 100%;
	  margin: 0 auto;
	}

	.form-field {
		margin:0 0 18px;
	}

	label {
		display: block;
		font-weight:500;
		margin: 0 0 5px;

		&.required-label {
			&:after {
				content:'*';
				color:$error-color;
			}
		}
	}
	input[type="text"],
	input[type="tel"],
	input[type="number"],
	input[type="email"],
	input[type="file"],
	input[type="password"],
	select,
	textarea {
		width: 100%;
		background:#fff;

		&[type="checkbox"],
		&[type="radio"] {
			width: auto;
		}

		&.input-validation-error {
			border:1px solid $error-color;
		}
	}
	input[type=checkbox], input[type=radio] {
		float:left;
		margin:4px 5px 5px 0;
	}
	.ktc-checkbox, .ktc-radio {
		display:flex;
		align-items: flex-start;

		label {
			width:100%;
		}

		input[type=checkbox] {
			margin:5px 5px 0 0;
			flex:1;
			min-width:30px;
		}
		input[type=radio] {
			margin:5px 5px 0 0;
			flex:1;
			min-width:30px; 
		}
	}
	.ktc-radio-list {
		display:block;

		.ktc-radio {

		}
	}
	.field-validation-error {
		color:$error-color;
		font-style: italic;
		margin:3px 0 0 0;
	}
	input[type="submit"] {
		@extend .button;
	}
}

.form-group {
	position: relative;

	&:not(:last-child) {
		margin-bottom: $form-spacing;
	}

	label {
		font-size: utilities.pxtorem(19px);
		font-weight: variables.$fontWeight-bold;
		position: absolute;
		pointer-events: none;
		left: variables.$grid-gutter;
		top: 30px;
		transform: translateY(-50%);
		transition: variables.$trans;
	}
	&.filled label {
		font-size: utilities.pxtorem(14px);
		top: 25%;
		color: variables.$color-purple;
		font-weight: variables.$fontWeight-extrabold;
	}

	input[type="checkbox"] {
		width: auto;
		cursor: pointer;
	}
	input[type="checkbox"] + label {
		color: variables.$color-base;
		position: static;
		font-size: utilities.pxtorem(16px);
		font-weight: variables.$fontWeight-normal;
		cursor: pointer;
		pointer-events: all;
	}
}

.form-control {
	width: 100%;
	height: $input-height;
	border-radius: 5px;
	padding-left: variables.$grid-gutter;
	font-family: variables.$font-primary;
	font-size: utilities.pxtorem(19px);
	font-weight: variables.$fontWeight-bold;
	background-color: #fff;
}
label + .form-control {
	padding-top: 20px;
}
select.form-control {
	cursor: pointer;
	padding-left: 25px;
}
input[type="text"],
input[type="tel"],
input[type="number"],
input[type="email"],
input[type="file"],
input[type="password"],
select,
textarea {
	&.input-validation-error {
		border-color: $error-color;
	}
}

/******************************** 
** login 
********************************/
.login__links {
	margin: 20px 0;
}


.form-radio-inline {
	display: flex;

	label {
		display: flex;
		margin-right: 20px;
		cursor: pointer;
	}
	input {
		width: auto;
		cursor: pointer;
	}
}
.application-form {
	.form-field {
		margin-bottom: variables.$grid-gutter;
	}
	.control-label,
	legend {
		font-weight: variables.$fontWeight-bold;
	}
	.required-marker {
		color: $error-color;
	}
}

._security,
._employment,
._balanceTransfer {
	margin-top: variables.$grid-gutter;
}
.field-validation-error {
	color: $error-color;
	font-weight: variables.$fontWeight-bold;
}

.application-form {
	@include utilities.breakpoint(variables.$bp-mw) {
		h2 {
			font-size: utilities.pxtorem(38px);
		}
	}
}