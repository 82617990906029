@use "../../utilities";
@use "../../variables";

/* ================================================================
   Cookie Consent
   ================================================================ */

.cookie-consent {
    padding:20px 0;
}
.cookie-consent-inner {
    padding: 0 variables.$grid-gutter;
    @include utilities.breakpoint(variables.$bp-mw) {
        display: flex;
        align-items: center;
    }
    @include utilities.breakpoint(variables.$container-size-large) {
        padding: 0;
    }
}
.cookie-consent__desc {
    color:variables.$color-invert;
    margin:0 0 1rem;
}
.btn--consent {
    margin-top: 20px;
    height: auto;
    @include utilities.breakpoint(variables.$bp-mw) {
        margin-left: 20px;
        margin-top: 0;
    }
}

// modal

.cookie-box {
    .modaal-container {
        background: variables.$color-gray;
    }
}