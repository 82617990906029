@use "sass:math";
@use "../../utilities";
@use "../../variables";

.page-banner {
    margin: 0 -#{variables.$grid-gutter} 30px;
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .btn {
        display: block;
    }
    @include utilities.breakpoint(variables.$bp-mw) {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;        
        align-items: center;
        text-align: left;
        margin: 0 0 40px;
        padding: 40px 0 0;
        overflow: inherit;

        & + & {
            margin-top: -60px - 1px;
        }

        .btn {
            display: inline-block;
        }
    }
    @include utilities.breakpoint(variables.$bp-xxl) {
        padding: 60px 0 0;
        margin: 0 0 60px;        
    }
}


.page-banner__figure {
    position: relative;
    margin-bottom: 40px;
    padding: 60px 40px 0;
    @include utilities.breakpoint(variables.$bp-mw) {
        flex: 1 1 auto;
        margin: 0 80px 0 0;
        padding: 0;
    }

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        bottom: -30px;
        right: -50px;
        position: absolute;
        border-radius: 50%;
        background: variables.$color-gradient;
        mix-blend-mode: normal;
        opacity: 0.8;
        filter: blur(40px);
    }
}
.page-banner__img {
    display: block;
    background: no-repeat center center / cover;
    border-radius: 50%;
    z-index: 1;
    position: relative;
    &::after {
        content: "";
        display: block;
        padding-top: math.percentage(math.div(1, 1));
    }

    &--square {
        border-radius: 0;
    }
}

.page-banner__content {
    font-size: utilities.pxtorem(18px);
    
    @include utilities.breakpoint(variables.$bp-mw) {
        width: 100%;
        margin-right: 40px;
        text-align: left;
    }
}


.page-banner__box {
    padding: 40px variables.$grid-gutter 0;
    @include utilities.breakpoint(variables.$bp-mw) {
        padding: 40px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
        width: 55%;
    }
}

.page-banner__title {
    padding: 0 variables.$grid-gutter;
}
.page-banner__desc {
    margin-bottom: variables.$grid-gutter;

    @include utilities.breakpoint(variables.$bp-mw) {
        max-width: 550px;
    }

    ul {
        // @include resetList;
        // margin: 20px 0;

        // li {
        //     padding-left: 40px;
        //     position: relative;
        //     &::before { 
        //         content: ''; 
        //         display: block; 
        //         position: absolute;
        //         top: 11px;
        //         left: 0;
        //         background-color: $color-secondary;
        //         width: 24px;
        //         height: 4px;
        //         border-radius: 2px;
        //     }
        // }
    }

    strong {
        font-weight: variables.$fontWeight-extrabold;
    }
}

.page-banner--flipped {
    @include utilities.breakpoint(variables.$bp-mw) {
        .page-banner__figure {
            order: 2;
            margin: 0 0 0 80px;
        }
    }
}

/******************************** 
** Page Banner Business 
********************************/
.section-container--page-banner {
    background-size: cover;
    background-position: center;
    position: relative;
    color: variables.$color-invert;
    padding-bottom: 10%;
    z-index: 1;

    @include utilities.breakpoint(1940px) {
        padding-bottom: 180px;
    }

    &::before { 
        content: ''; 
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(35deg, #000 0%, transparent 100%);
        opacity: .7;
        z-index: -1;
    }
    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: -1px;
        width: 100%;
        height: 100%;
        background-image: url(../images/masks/page-banner-mask.png);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: contain;
        z-index: -1;

        @include utilities.breakpoint(1940px) {
            background-size: 100% 180px;
        }
    }

    .breadcrumbs__link {
        color: variables.$color-invert;
    }
    .breadcrumbs__item::after {
        background-color: variables.$color-invert;
    }
    .page-banner {
        margin-bottom: 0;
    }
    .page-banner__title {
        margin-bottom: 0;
    }
}

/******************************** 
** Page Banner general 
********************************/
.page-banner--general {
    position: relative;
    padding:60px 0;
    margin:0;

    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom:0;
        width: 100%;
        height: 10px;
        background-image: radial-gradient(circle at 5px 5px, #C4D82E 3px, transparent 0);
        background-size: 30px 10px;
        background-repeat: repeat-x;
    }

    .page-banner__box {
        width:auto;
        display:block;
        padding:0;
        flex: 1;
    }
    .page-banner__title {
        padding:0;
        margin:0;
    }
}