@use "../../utilities";
@use "../../variables";

// Featured 

.featured-blog {

}
.featured-blog__top {
    margin:0 0 15px;
}

.featured-blog__inner-left {
    margin:0 0 30px;
}


.featured-blog__list {
    list-style: none;
    margin:0;
    padding:0;
}
.featured-blog__item {
    margin:0 0 20px;
    padding:0 0 20px;
    border-bottom:1px solid #ddd;

    &:last-child {
        border:0;
        margin:0;
        padding:0;
    }
}
.featured-blog__list-title {
    margin:0 0 15px;

    a {
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}
.featured-blog__list-description {

}

@media (min-width: variables.$bp-mw) {
    .featured-blog__top {
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin:0;
    }
    .featured-blog__inner {
        display:flex;
    }
    .featured-blog__inner-left {
        width:60%;
        margin:0;
    }
    .featured-blog__inner-right {
        padding:0 0 0 5%;
        width:40%;
    }
}

.blog-listing {
    list-style: none;
    margin:0;
    padding:0;
}

.blog-listing__item {
    margin:0 0 20px;
}

.blog-card {
    border:1px solid #f2f3f5;
    display:block;
    text-decoration: none;
    height:100%;

    &:hover,
    &:focus {
        .blog-card__title {
            text-decoration: underline;
        }
    }
}

.blog-card__img {
    position: relative;
    background: no-repeat center center / cover;

    &:before {
        content:'';
        display:block;
        padding-top: 66.66%;
    }
}

.blog-card__content {
    padding:30px 25px 25px;
}

.blog-card__title {
    margin:0 0 15px;

    a {
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

.blog-card__description {

}

@media (min-width: variables.$bp-m) {

    .blog-listing {
        display:flex;
        gap:20px;
    }
    
    .blog-listing__item {
        width:50%;
        margin:0;
    }
}
@media (min-width: variables.$bp-mw) {

    .blog-listing__item {
        width:33.33%;
    }
}

/* Blog Details */
.blog-detail__pagination {
    display:flex;
    justify-content: flex-end;
    margin:0 0 10px;
    gap:15px;

    

    a, span {
        text-decoration: none;
        display:flex;
        gap:3px;
        align-items: center;
        font-weight:bold;

        svg {
            width:14px;
            height:14px;
            fill:currentColor;
        }
    }
    span {
        opacity: .7;
    }
    a {
        &:hover,
        &:focus {
            color:variables.$color-purple;
        }
    }
}
.blog-detail {
    max-width:1200px;
    margin:0 auto;
    padding:20px 0;
}
.blog-detail__image {
    margin:0 0 20px;
}
.blog-detail__heading {
    margin:0 0 20px;
    font-size: utilities.pxtorem(56px);
}
.blog-detail__body {
    line-height:1.5;
}
.blog-detail__date {
    border-top:1px solid #ddd;
    border-bottom:1px solid #ddd;
    margin:20px 0 0;
    padding:5px 0;
}

.blog-detail__related {
    margin:30px 0 0;

    h2 {
        font-size: utilities.pxtorem(40px);
    }
}

/*  Blog Carousel */
.blog-carousel-container {
    position: relative;
}
.blog-carousel__arrow {
    position: absolute;
    top:90px;
    background:rgba(0, 0, 0,.7);
    z-index:10;
    
    &:after {
        border-color:#fff;
    }

    &.blog-carousel__arrow--prev {
        left:10px;
        &:after {
            transform: rotate(-135deg);
        }  
    }
    &.blog-carousel__arrow--next {
        right:10px;
    }
}
.blog-carousel__card {
    text-decoration: none;
    display:block;
    margin:0 10px;

    &:hover,
    &:focus {

        .blog-carousel__card-heading {
            text-decoration: underline;
        }
    }
}

.blog-carousel__card-image {
    height:200px;
    background-size:cover;
    background-position: 50% 50%;
    margin: 0 0 7px;
}

.blog-carousel__card-heading {
    font-size: utilities.pxtorem(20px);
    text-align: center;
}