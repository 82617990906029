@use "sass:math";
@use "../../utilities";
@use "../../variables";
@use "../../globals/buttons";

/* ================================================================
   Featured Articles
================================================================ */

.featured-top {
    @include utilities.breakpoint(variables.$bp-mw) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .title {
        margin-bottom: 0;
    }
}
.featured-container {
    margin: 40px auto;
    @include utilities.breakpoint(variables.$bp-mw) {
        display: flex;
    }
}
.featured__left {
    margin-bottom: variables.$grid-gutter;
    @include utilities.breakpoint(variables.$bp-mw) {
        margin-bottom: 0;
        margin-right: 100px;
        width: 45%;
    }
}
.featured__right {
    @include utilities.breakpoint(variables.$bp-mw) {
        flex: 1;
    }
}
.featured__left {
    .featured__figure {
        margin-bottom: variables.$grid-gutter;
        position: relative;
        &::before {
            content: "";
            width: 100%;
            height: 100%;
            bottom: -20px;
            right: -20px;
            position: absolute;
            border-radius: 50%;
            background: variables.$color-gradient;
            mix-blend-mode: normal;
            opacity: 0.6;
            filter: blur(60px);
        }
    }
    .featured__img {
        display:block;
        background: no-repeat center center / cover;
        border-radius: 40px;
        position: relative;
        &::after { 
            content: ''; 
            display: block; 
            padding-top: math.percentage(math.div(600, 800)); 
        }
    }
    .featured__title {
        margin-bottom: variables.$grid-gutter;
        @include utilities.fluid-type(variables.$bp-s, variables.$container-size-em, 30px, 48px);
    }
    .featured__link {
        margin-top: variables.$grid-gutter;
        @extend .btn, .btn--purple;
    }
}
.featured__right {
    .featured__list {
        @include utilities.resetList;
    }
    .featured__item {
        border-top: 1px solid variables.$color-dark-gray;
        padding: 20px 0;
        &:last-child {
            border-bottom: 1px solid variables.$color-dark-gray;
        }
        @include utilities.breakpoint(variables.$bp-mw) {
            padding: 50px
        }
    }
    .featured__title {
        margin-bottom: 10px;
        @include utilities.fluid-type(variables.$bp-s, variables.$container-size-em, 24px, 30px);
    }
    .featured__link {
        padding: 0;
        @extend .btn, .btn--link, .btn--underline;
    }
}