@use "../../utilities";
@use "../../variables";

/******************************** 
** PDF Disclosure 
********************************/

.pdf-disclosure {
    margin: 0 0 30px;
}
.pdf-disclosure-text {

}
.pdf-disclosure-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}
.pdf-disclosure-link {
    font-weight: variables.$fontWeight-extrabold;
    color:variables.$color-purple;
    font-size: utilities.pxtorem(18px);
    text-decoration: none;

    &:hover, 
    &:active, 
    &:focus {
        text-decoration: underline;
    }
}
.pdf-disclosure-confirm {

}